import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { FormControl } from '@mui/material'
import { MultiSelect } from 'react-multi-select-component'
import { Option } from 'react-multi-select-component/dist/types/lib/interfaces'
import { isCompanyAdmin } from '../../../../helpers/checkRole'
import { User } from '../../../../store/Auth/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import React from 'react'

interface StoresToolbarProps {
  clearSearch: () => void
  onChange: (event: { target: { value: string } }) => void
  setSelectedCompanies: (companies: Option[]) => void
  submitSearch: (value: string) => void
  value: string
  companies: Option[]
  selectedCompanies: Option[]
  user: User
}

export default function StoresToolbar(props: StoresToolbarProps) {
  const { t } = useTranslation()

  const customValueRenderer = (selected: { label: any }[], _options: any) => {
    return selected.length
      ? t('multiSelect.selectedItems', { length: selected.length })
      : t('multiSelect.select')
  }

  const onSearchKeyDown = (e: { keyCode: number }) => {
    // on enter press
    if (e.keyCode === 13) {
      props.submitSearch(props.value)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        marginTop: 0,
        alignItems: 'flex-end',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!isCompanyAdmin(props.user) && (
          <FormControl
            size="small"
            sx={{ minWidth: 180, marginRight: 1, zIndex: 6 }}
          >
            <label>{t('pages.stores.toolbar.filters.companies')}</label>
            <MultiSelect
              options={props.companies}
              value={props.selectedCompanies}
              onChange={props.setSelectedCompanies}
              labelledBy={t('multiSelect.labelledBy')}
              overrideStrings={{
                allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
                clearSearch: t('multiSelect.clearSearch'),
                noOptions: t('multiSelect.noOptions'),
                search: t('multiSelect.search'),
                selectAll: t('multiSelect.selectAll'),
                selectAllFiltered: t('multiSelect.selectAllFiltered'),
                selectSomeItems: t('multiSelect.selectSomeItems'),
              }}
              valueRenderer={customValueRenderer}
            />
          </FormControl>
        )}
      </div>
      <div>
        <TextField
          variant="outlined"
          size="small"
          value={props.value}
          onChange={props.onChange}
          placeholder={t('pages.stores.table.search')}
          onKeyDown={onSearchKeyDown}
          InputProps={{
            style: {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            } as React.CSSProperties,
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          inputProps={{ maxLength: 100 }}
        />
        <PrimaryButton
          onClick={() => props.submitSearch(props.value)}
          style={{
            padding: '7px 5px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: '40px',
          }}
        >
          {t('common.search')}
        </PrimaryButton>
      </div>
    </div>
  )
}
