import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { IconButton, SelectChangeEvent, Stack } from '@mui/material'
import PeriodService from '../../../../services/period.service'
import CompanyService from '../../../../services/company.service'
import UserPlanService from '../../../../services/userPlan.service'
import { useTranslation } from 'react-i18next'
import UserPlansToolbar from '../partials/UserPlansToolbar'
import { escapeRegExp, thousandsSeparator } from '../../../../helpers/utils'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import { UserPlan, UserPlanResult } from '../../../../store/UserPlan/types'
import { Option } from 'react-multi-select-component/dist/types/lib/interfaces'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import UserPlanDialog from '../partials/UserPlanDialog'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Link } from 'react-router-dom'
import { ReactComponent as SetPlansIcon } from '../../../../assets/images/icons/set_plans.svg'
import { User } from '../../../../store/Auth/types'
import { Column } from 'react-table'
import Table from '../../../Table/Table'

type UserPlansListProps = {
  path: string
  user: User
}

interface Legend extends UserPlanResult {
  visible: boolean
}

const UserPlansList: FunctionComponent<UserPlansListProps> = ({
  path,
  user,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [legend, setLegend] = useState<Legend[]>([])
  const [userPlansList, setUserPlansList] = useState<UserPlan[]>([])
  const [filteredUserPlansList, setFilteredUserPlansList] = useState<
    UserPlan[]
  >([])
  const [searchText, setSearchText] = useState<string>('')
  const [periodValue, setPeriodValue] = useState<string>('')
  const [userId, setUserId] = useState<number | null>(null)
  const [periods, setPeriods] = useState<Period[]>([])
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [openUserPlanDialog, setUserPlanDialogOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const handleUserPlanDialogClickOpen = (userId: number) => {
    setUserId(userId)
    setUserPlanDialogOpen(true)
  }

  const handleUserPlanDialogClose = (refreshTable: boolean = false) => {
    setUserPlanDialogOpen(false)
    setRefresh(refreshTable)
  }

  const requestSearch = (searchValue: string) => {
    let filteredRows: UserPlan[] = userPlansList

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    filteredRows = filteredRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && searchRegex.test(row[field].toString())
      })
    })
    setFilteredUserPlansList(filteredRows)
  }

  const generateTableColumns = useCallback(
    (userPlans: UserPlan[], periodId: number) => {
      const columns = []
      columns.push({
        Header: ' ',
        sticky: 'left',
        columns: [
          {
            accessor: 'edit',
            Header: '',
            sticky: 'left',
            width: 55,
            disableSortBy: true,
            Cell: (params: any) => (
              <IconButton
                onClick={() =>
                  handleUserPlanDialogClickOpen(params.row.values.id)
                }
                size="small"
                style={{ padding: 0 }}
              >
                <SetPlansIcon />
              </IconButton>
            ),
          },
          {
            Header: t('pages.userPlans.table.id').toString(),
            accessor: 'id',
            sticky: 'left',
            width: 50,
            Cell: (params: any) => (
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Link
                  to={`/store-plans/${params.row.values.id}/${periodId}`}
                  style={{ color: 'rgba(0,0,0,.87)' }}
                >
                  {params.value}
                </Link>
              </div>
            ),
          },
          {
            accessor: 'username',
            Header: t('pages.userPlans.table.username').toString(),
            sticky: 'left',
            width: 260,
            Cell: (params: any) => (
              <Link
                to={`/store-plans/${params.row.values.id}/${periodId}`}
                style={{ color: 'rgba(0,0,0,.87)' }}
              >
                {params.value}
              </Link>
            ),
          },
          {
            accessor: 'regionName',
            Header: t('pages.userPlans.table.regionName').toString(),
            sticky: 'left',
            width: 170,
            Cell: (params: any) => (
              <Link
                to={`/store-plans/${params.row.values.id}/${periodId}`}
                style={{ color: 'rgba(0,0,0,.87)' }}
              >
                {params.value}
              </Link>
            ),
          },
          {
            accessor: 'storesAmount',
            Header: t('pages.userPlans.table.storesAmount').toString(),
            sticky: 'left',
            width: 85,
            Cell: (params: any) => (
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Link
                  to={`/store-plans/${params.row.values.id}/${periodId}`}
                  style={{ color: 'rgba(0,0,0,.87)' }}
                >
                  {params.value}
                </Link>
              </div>
            ),
          },
        ],
      })

      userPlans.forEach((userPlan, j) =>
        userPlan.planResults.forEach((planResult, i) => {
          if (j === 0) {
            columns.push({
              Header: planResult.name,
              columns: [
                {
                  id: `${t('pages.userPlans.table.planValue')}--${i}`,
                  Header: t('pages.userPlans.table.planValue').toString(),
                  width: 90,
                  accessor: (data: any) => {
                    return data.planResults[i] ? data.planResults[i].planValue : 0
                  },
                  Cell: (params: any) => (
                    <div style={{ width: '100%', textAlign: 'right' }}>
                      {thousandsSeparator(params.value)}
                    </div>
                  ),
                },
                {
                  id: `${t('pages.userPlans.table.resultValue')}--${i}`,
                  Header: t('pages.userPlans.table.resultValue').toString(),
                  width: 90,
                  accessor: (data: any) => {
                    return data.planResults[i] ? data.planResults[i].resultValue : 0
                  },
                  Cell: (params: any) => (
                    <div style={{ width: '100%', textAlign: 'right' }}>
                      {thousandsSeparator(params.value)}
                    </div>
                  ),
                },
                {
                  id: `${t('pages.userPlans.table.resultPercentValue')}--${i}`,
                  Header: t(
                    'pages.userPlans.table.resultPercentValue',
                  ).toString(),
                  width: 100,
                  accessor: (data: any) => {
                    return data.planResults[i] ? data.planResults[i].resultPercentValue : 0
                  },
                  Cell: (params: any) => (
                    <div style={{ width: '100%', textAlign: 'right' }}>
                      {thousandsSeparator(params.value)}%
                    </div>
                  ),
                },
              ],
            })
          }
        }),
      )
      return columns
    },
    [t],
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const periodListResponse = await PeriodService.getPeriodList()

        if (periodListResponse.data.periodList) {
          setPeriods(periodListResponse.data.periodList)

          const companyListResponse = await CompanyService.getCompanyList()

          if (companyListResponse.data.companies) {
            const multiSelectOptions: Option[] = []
            companyListResponse.data.companies.forEach((company) =>
              multiSelectOptions.push({
                value: company.id,
                label: company.name,
              }),
            )
            setCompanies(multiSelectOptions)
            setSelectedCompanies(multiSelectOptions)

            const activePeriodOrNewest =
              periodListResponse.data.periodList.find(
                (period) => period.isActive,
              ) ||
              periodListResponse.data.periodList[
                periodListResponse.data.periodList.length - 1
              ]

            setPeriodValue(activePeriodOrNewest.id.toString())
            const userPlansResponse = await UserPlanService.getUserPlans(
              activePeriodOrNewest.id,
              companyListResponse.data.companies.map((company) => company.id),
            )

            if (userPlansResponse.data.userPlans) {
              setTableColumns(
                generateTableColumns(
                  userPlansResponse.data.userPlans,
                  activePeriodOrNewest.id,
                ),
              )

              setUserPlansList(userPlansResponse.data.userPlans)
              setFilteredUserPlansList(userPlansResponse.data.userPlans)

              if (userPlansResponse.data.userPlans.length > 0) {
                setLegend(
                  userPlansResponse.data.userPlans[0].planResults.map(
                    (plan) => {
                      return {
                        ...plan,
                        visible: true,
                      }
                    },
                  ),
                )
              } else {
                setLegend([])
              }
            }
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [path, t, generateTableColumns])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userPlansResponse = await UserPlanService.getUserPlans(
          parseInt(periodValue),
          selectedCompanies.map((company) => company.value),
        )

        if (userPlansResponse.data.userPlans) {
          setTableColumns(
            generateTableColumns(
              userPlansResponse.data.userPlans,
              parseInt(periodValue),
            ),
          )

          setUserPlansList(userPlansResponse.data.userPlans)
          setFilteredUserPlansList(userPlansResponse.data.userPlans)

          if (userPlansResponse.data.userPlans.length > 0) {
            setLegend(
              userPlansResponse.data.userPlans[0].planResults.map((plan) => {
                return {
                  ...plan,
                  visible: true,
                }
              }),
            )
          } else {
            setLegend([])
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (periodValue !== '') {
      setSearchText('')
      fetchData()
    }
  }, [periodValue, t, selectedCompanies, refresh, generateTableColumns])

  const downloadXLSX = (data: UserPlan[], name: string) => {
    // prepare data for export
    const preparedData = data.map((d) => {
      const dataToReturn: any = {
        id: d.id,
        username: d.username,
        regionName: d.regionName,
        storesAmount: d.storesAmount,
      }
      d.planResults.forEach((plan) => {
        dataToReturn[`${plan.name} planValue`] = Number(plan.planValue)
        dataToReturn[`${plan.name} resultValue`] = Number(plan.resultValue)
        dataToReturn[`${plan.name} resultPercentValue`] =
          Number(plan.resultPercentValue)
      })
      return dataToReturn
    })

    const fileName = `${name}.xlsx`
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(preparedData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, name)

    XLSX.writeFile(wb, fileName)
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack display="flex" alignContent="end">
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX(filteredUserPlansList, 'user-plans')}
              sx={{ marginLeft: 'auto' }}
            >
              {t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <UserPlansToolbar
            user={user}
            periods={periods}
            companies={companies}
            selectedCompanies={selectedCompanies}
            value={searchText}
            legend={legend}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              requestSearch(event.target.value)
            }}
            periodValue={periodValue}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
              requestSearch(searchText)
            }}
            setSelectedCompanies={setSelectedCompanies}
            clearSearch={() => {
              setSearchText('')
              requestSearch('')
            }}
            togglePlanVisibility={(planId: number) => {
              let updatedLegend = legend.map((el) => {
                if (el.id === planId) {
                  el.visible = !el.visible
                }
                return el
              })
              setLegend(updatedLegend)
              const filteredUserPlansListWithVisibility = userPlansList.map(
                (userPlan) => {
                  return {
                    ...userPlan,
                    planResults: userPlan.planResults.filter((planResult) =>
                      legend
                        .filter((l) => l.visible)
                        .map((l2) => l2.id)
                        .includes(planResult.id),
                    ),
                  }
                },
              )
              setFilteredUserPlansList(filteredUserPlansListWithVisibility)
              setTableColumns(
                generateTableColumns(
                  filteredUserPlansListWithVisibility,
                  parseInt(periodValue),
                ),
              )
            }}
          />
          <Table
            columns={tableColumns}
            data={filteredUserPlansList}
            height="calc(100vh - 300px)"
          />
          {userId && (
            <UserPlanDialog
              open={openUserPlanDialog}
              handleClose={handleUserPlanDialogClose}
              periodId={parseInt(periodValue)}
              userId={userId}
            />
          )}
        </>
      )}
    </>
  )
}

export default UserPlansList
