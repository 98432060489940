import React from 'react'
import {
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  TextField,
  InputAdornment,
} from '@mui/material'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import { useTranslation } from 'react-i18next'
import { ChallengeStep3Points } from '../../../../store/Challenge/types'

type ChallengeLevelFormProps = {
  level: [string, boolean]
  points: ChallengeStep3Points
  handleLevelChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleLevelPointsChange: (
    levelName: string,
    points: ChallengeStep3Points,
  ) => void
}

const ChallengeLevelForm: React.FunctionComponent<ChallengeLevelFormProps> = ({
  level,
  points,
  handleLevelChange,
  handleLevelPointsChange,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={level[1]}
            onChange={handleLevelChange}
            name={level[0]}
          />
        }
        label={t(`pages.challenge.step3.${level[0]}`)}
      />
      <Grid container spacing={1} marginBottom={2}>
        <Grid item xs={3}>
          <FormControl>
            <label>{t('pages.challenge.step3.xp')}</label>
            <TextField
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ThumbUpIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              disabled={!level[1]}
              name="xpPlus"
              value={points.xpPlus !== null ? points.xpPlus : ''}
              onChange={(event) =>
                handleLevelPointsChange(level[0], {
                  ...points,
                  [event.target.name]: Math.abs(parseInt(event.target.value)),
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl>
            <label>{t('pages.challenge.step3.vp')}</label>
            <TextField
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ThumbUpIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              disabled={!level[1]}
              name="vpPlus"
              value={points.vpPlus !== null ? points.vpPlus : ''}
              onChange={(event) =>
                handleLevelPointsChange(level[0], {
                  ...points,
                  [event.target.name]: Math.abs(parseInt(event.target.value)),
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl>
            <label>{t('pages.challenge.step3.xp')}</label>
            <TextField
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ThumbDownIcon color="error" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              error
              disabled={!level[1]}
              name="xpMinus"
              value={points.xpMinus !== null ? points.xpMinus : ''}
              onChange={(event) =>
                handleLevelPointsChange(level[0], {
                  ...points,
                  [event.target.name]: Math.abs(parseInt(event.target.value)),
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl>
            <label>{t('pages.challenge.step3.vp')}</label>
            <TextField
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ThumbDownIcon color="error" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              error
              disabled={!level[1]}
              name="vpMinus"
              value={points.vpMinus !== null ? points.vpMinus : ''}
              onChange={(event) =>
                handleLevelPointsChange(level[0], {
                  ...points,
                  [event.target.name]: Math.abs(parseInt(event.target.value)),
                })
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default ChallengeLevelForm
