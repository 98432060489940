import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import UserPlanService from '../../../../services/userPlan.service'
import { errorHandler } from '../../../../helpers/errorHandler'
import {
  UserPlanEditData,
  UserPlanValue,
} from '../../../../store/UserPlan/types'
import { styled } from '@mui/material/styles'
import PlanImage from './PlanImage'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { toast } from 'react-toastify'

const PlanDetailsBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#efefef',
  fontSize: '.8rem',
  padding: '10px',
  borderRadius: '4px',
  span: {
    fontWeight: 'bold',
  },
})

type UserPlanDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  periodId: number
  userId: number
}

const UserPlanDialog: React.FunctionComponent<UserPlanDialogProps> = ({
  open,
  handleClose,
  periodId,
  userId,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [planDetails, setPlanDetails] = useState<UserPlanEditData | null>(null)
  const [plans, setPlans] = useState<UserPlanValue[]>([])

  const handlePlanValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    i: number,
  ) => {
    setPlans((prevState) => {
      let updatedPlans = prevState
      updatedPlans[i].planValue = parseInt(event.target.value)
      return updatedPlans
    })
  }

  const confirmPlanValues = async () => {
    const plansWithValues = plans.filter((plan) => plan.planValue || plan.planValue === 0)
    if (plansWithValues.length === 0) {
      toast.error(t('pages.userPlans.messages.oneFieldIsRequired'))
    } else {
      try {
        const confirmResponse =
          await UserPlanService.confirmPlanValuesByUserIdAndPeriodId({
            userId,
            plans: plansWithValues,
          })

        if (confirmResponse.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
          handleClose(true)
        } else {
          toast.error(t('messages.error.generalError'))
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const planDetailsResponse =
          await UserPlanService.getPlanValuesByUserIdAndPeriodId(
            periodId,
            userId,
          )

        if (planDetailsResponse.data) {
          setPlans(
            planDetailsResponse.data.plans.map((plan) => {
              return {
                planId: plan.planId,
                planValue: null,
              }
            }),
          )
          setPlanDetails(planDetailsResponse.data)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [open, t, userId, periodId])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && planDetails && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={open}
          onClose={() => handleClose(false)}
        >
          <DialogTitle>
            <Typography variant="body1" fontWeight="bold">
              {t('pages.userPlans.dialog.title')} | {planDetails.periodName}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <PlanDetailsBox>
                <Stack flexDirection="column">
                  <span>{t('pages.userPlans.dialog.id')}:</span>
                  {planDetails.userId}
                </Stack>
                <Stack flexDirection="column">
                  <span>{t('pages.userPlans.dialog.centralId')}:</span>
                  {planDetails.userCentralId ? planDetails.userCentralId : '-'}
                </Stack>
                <Stack flexDirection="column">
                  <span>{t('pages.userPlans.dialog.internalId')}:</span>
                  {planDetails.userInternalId
                    ? planDetails.userInternalId
                    : '-'}
                </Stack>
                <Stack flexDirection="column">
                  <span>{t('pages.userPlans.dialog.username')}:</span>
                  {planDetails.username}
                </Stack>
                <Stack flexDirection="column">
                  <span>{t('pages.userPlans.dialog.companyName')}:</span>
                  {planDetails.companyName}
                </Stack>
              </PlanDetailsBox>
            </DialogContentText>
            <Stack flexDirection="row" mt={3}>
              {planDetails.plans.map((plan, i) => (
                <Stack
                  flexDirection="column"
                  mx={1}
                  key={plan.planId}
                  alignItems="center"
                >
                  {plan.imageId && <PlanImage imageId={plan.imageId} />}
                  <Typography variant="body2" fontWeight="bold">
                    {plan.planName}
                  </Typography>
                  <TextField
                    disabled
                    value={plan.planValue}
                    size="small"
                    sx={{ margin: '10px 0', background: '#efefef' }}
                  />
                  {plan.isEditable && (
                    <TextField
                      size="small"
                      type="number"
                      disabled={!plan.isEditable}
                      value={plans[i].planValue}
                      onChange={(e) => handlePlanValueChange(e, i)}
                    />
                  )}
                </Stack>
              ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <SecondaryButton onClick={() => handleClose(false)}>
                {t('common.cancel')}
              </SecondaryButton>
              <PrimaryButton
                onClick={confirmPlanValues}
                disabled={!planDetails.plans[0].isEditable}
              >
                {t('common.save')}
              </PrimaryButton>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default UserPlanDialog
