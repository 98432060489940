import React, { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Copyright from '../shared/Copyright'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Alert,
  TextField,
  CircularProgress,
  Grid,
  Box,
  Link,
  InputAdornment,
  AlertColor,
} from '@mui/material'
import PrimaryButton from '../../../styles/Buttons/PrimaryButton'
import { styled } from '@mui/material/styles'
import bgImage from '../../../assets/images/background/login-bg.png'
import ActionLogo from '../../../assets/images/logos/action-logo.png'
import LockIcon from '@mui/icons-material/Lock'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PasswordService from '../../../services/password.service'
import { handleNavigationClick } from '../../../helpers/utils'
import { errorHandler } from '../../../helpers/errorHandler'
import LoadingSpinner from '../../shared/LoadingSpinner'

type PasswordResetProps = {
  history: string[]
}
const PasswordResetContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  marginTop: '-100px',
  height: '100%',
  width: '100% !important',
  minWidth: '310px',
  maxWidth: 'unset !important',
  backgroundImage: `url(${bgImage})`,
  backgroundPosition: 'center',
  backgroundPositionY: -60,
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  padding: 0 + '!important',
  '.logo-container': {
    width: 160,
    height: 80,
    marginBottom: 20,
    '.logo': {
      width: '100%',
    },
  },
  '.login-box': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'min-content',
    left: '100px',
    backgroundColor: '#FFF',
    boxShadow: theme.shadows[3],
    borderRadius: '25px',
    width: '100%',
    maxWidth: '350px !important',
    padding: '40px',
    '.login-icons': {
      fill: theme.colorsPalette.actionColors.primary.main,
    },
    '.login-button-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px 0px',
    },
    '.forgot-password-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      button: {
        marginLeft: 10,
        color: theme.colorsPalette.actionColors.primary.main,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  '.footer-container': {
    width: '100%',
    height: '60px',
    backgroundColor: '#FFF',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '.action-logo-container': {
      position: 'absolute',
      bottom: 30,
      left: 180,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 170,
      height: 80,
      '.logo': {
        width: '100%',
        height: '100%',
      },
    },
    '.footer-copyright-container': {
      width: '100%',
      paddingLeft: 500,
    },
  },
}))

interface ParamTypes {
  token: string
}

const PasswordReset: FunctionComponent<PasswordResetProps> = () => {
  const { t } = useTranslation()
  let { token } = useParams<ParamTypes>()
  const passwordRegex: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*)[0-9A-Za-z@#\-_$%^&+=§!?*()]{8,15}$/g

  const [loading, setLoading] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [tokenIsAvailable, setTokenIsAvailable] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [password2, setPassword2] = useState<string>('')
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [password2Error, setPassword2Error] = useState<boolean>(false)
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState<boolean>(false)
  const [passwordResetAlert, setPasswordResetAlert] = useState<{
    severity: AlertColor
    message: string
  } | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const passwordResetCheckResponse =
          await PasswordService.passwordResetCheck(token)
        setTokenIsAvailable(passwordResetCheckResponse.data.isAvailable)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
        setPasswordError(false)
        setPassword2Error(false)
        setPasswordResetAlert(null)
        setPasswordResetSuccessful(false)
      }
    }
    fetchData()
  }, [t, token])

  const onChangePassword = (e: { target: { value: string } }) => {
    const password = e.target.value
    setPassword(password)
    setPasswordError(false)
  }

  const onChangePassword2 = (e: { target: { value: string } }) => {
    const password2 = e.target.value
    setPassword2(password2)
    setPassword2Error(false)
  }

  const handlePasswordResetConfirm = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    if (password.length === 0) {
      setPasswordError(true)
      return
    }
    if (password2.length === 0) {
      setPassword2Error(true)
      return
    }
    if (!password.match(passwordRegex)) {
      setPasswordResetAlert({
        severity: 'error',
        message: t('pages.passwordReset.validation.regexError'),
      })
      return
    }
    if (password !== password2) {
      setPasswordResetAlert({
        severity: 'error',
        message: t('pages.passwordReset.validation.matchError'),
      })
      return
    }
    setIsSubmitting(true)

    try {
      const passwordResetConfirmResponse = await PasswordService.passwordResetConfirm(password, password2, token)
      if (passwordResetConfirmResponse.data.success) {
        setPasswordResetAlert({
          severity: 'success',
          message: t('pages.passwordReset.passwordResetSuccess'),
        })
        setPasswordResetSuccessful(true)
      } else {
        setPasswordResetAlert({
          severity: 'error',
          message: t('messages.error.generalError'),
        })
      }
    } catch (error) {
      setPasswordResetAlert({
        severity: 'error',
        message: t('messages.error.generalError'),
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <PasswordResetContainer>
      <div className="login-box">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {loading && <LoadingSpinner />}
          {!loading && !tokenIsAvailable && !passwordResetSuccessful && (
            <>
              <Alert severity="error" style={{ marginBottom: '1.5rem' }}>
                {t('pages.passwordReset.tokenError')}
              </Alert>
              <Grid container>
                <Grid className="forgot-password-container" item xs>
                  <ArrowBackIcon className="login-icons" />
                  <Link
                    onClick={() => handleNavigationClick('')}
                    variant="body2"
                    component="button"
                  >
                    {t('pages.passwordReset.backToHome')}
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
          {!loading && tokenIsAvailable && !passwordResetSuccessful && (
            <Box
              component="form"
              onSubmit={handlePasswordResetConfirm}
              noValidate
              sx={{ mt: 1 }}
            >
              {passwordResetAlert && (
                <Alert severity={passwordResetAlert.severity} style={{ marginBottom: '1.5rem' }}>
                  {passwordResetAlert.message}
                </Alert>
              )}
              <TextField
                error={passwordError}
                helperText={passwordError ? t('pages.passwordReset.passwordError') : ''}
                variant="outlined"
                margin="normal"
                value={password}
                fullWidth
                name="password"
                label={t('pages.passwordReset.password')}
                type="password"
                onChange={onChangePassword}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon className="login-icons" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                error={password2Error}
                helperText={password2Error ? t('pages.passwordReset.password2Error') : ''}
                variant="outlined"
                margin="normal"
                value={password2}
                fullWidth
                name="password2"
                label={t('pages.passwordReset.password2')}
                type="password"
                onChange={onChangePassword2}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon className="login-icons" />
                    </InputAdornment>
                  ),
                }}
              />
              <Grid className="login-button-container" container>
                <PrimaryButton
                  className="login-button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t('pages.passwordReset.changePassword')}
                  {isSubmitting && (
                    <CircularProgress
                      size={18}
                      color="secondary"
                      style={{ marginLeft: '1rem' }}
                    />
                  )}
                </PrimaryButton>
              </Grid>
            </Box>
          )}
          {!loading && tokenIsAvailable && passwordResetSuccessful && (
            <Box
              sx={{ mt: 1 }}
            >
              {passwordResetAlert && (
                <Alert severity={passwordResetAlert.severity} style={{ marginBottom: '1.5rem' }}>
                  {passwordResetAlert.message}
                </Alert>
              )}
              <Grid className="login-button-container" container>
                <PrimaryButton
                  className="login-button"
                  onClick={() => handleNavigationClick('')}
                >
                  {t('pages.passwordReset.login')}
                  {isSubmitting && (
                    <CircularProgress
                      size={18}
                      color="secondary"
                      style={{ marginLeft: '1rem' }}
                    />
                  )}
                </PrimaryButton>
              </Grid>
            </Box>
          )}
        </Box>
      </div>
      <Box className="footer-container" mt={8}>
        <div className="action-logo-container">
          <img className="logo" src={ActionLogo} alt="" />
        </div>
        <div className="footer-copyright-container">
          <Copyright />
        </div>
      </Box>
    </PasswordResetContainer>
  )
}

export default PasswordReset
