import { styled } from '@mui/material/styles'
import { Button, ButtonProps } from '@mui/material'

const SecondaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.buttons.color,
  backgroundColor: theme.buttons.secondary.backgroundColor,
  borderColor: theme.buttons.secondary.border.color,
  borderWidth: theme.buttons.border.width,
  borderStyle: theme.buttons.border.style,
  padding: theme.buttons.secondary.padding,
  textTransform: theme.buttons.textTransform,
  borderRadius: theme.buttons.borderRadius,
  '&:hover': {
    backgroundColor: theme.buttons.backgroundColorHover,
  },
}))

export default SecondaryButton
