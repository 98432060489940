import React, { useEffect, useRef, useState } from 'react'
import { Alert, AlertColor, Box, Collapse, Stack } from '@mui/material'
import ChallengeService from '../../../../../services/challenge.service'
import { ChallengeLevels } from '../../../../../store/Challenge/types'
import UserService from '../../../../../services/user.service'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { toast } from 'react-toastify'

import * as XLSX from 'xlsx'
import {
  ChallengeLevelImport,
  ChallengeLevelsData,
  ChallengeLevelsImportParams,
} from '../../../../../store/Challenge/types'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
const { read, utils } = XLSX

const Input = styled('input')({
  display: 'none',
})

const StyledLabel = styled('label')(({ theme }) => ({
  color: theme.colorsPalette.gray.gray6,
  fontWeight: 'bold',
  fontSize: '.75rem',
  display: 'block',
  marginBottom: '0.5em',
}))

type ChallengeImportFormProps = {
  challengeId: number | null
  challengeLevels: ChallengeLevels
  isImportPossible: boolean
  isStep3Touched: boolean
}

const ChallengeImportForm: React.FunctionComponent<ChallengeImportFormProps> =
  ({ challengeId, challengeLevels, isImportPossible, isStep3Touched }) => {
    const { t } = useTranslation()
    const [loadingImport, setLoadingImport] = useState<boolean>(true)
    const [savingImport, setSavingImport] = useState<boolean>(false)
    const [userChallengeLevelsImport, setChallengeLevelsImport] =
      useState<ChallengeLevelsImportParams | null>(null)
    const [helperImportData, setHelperImportData] = useState<
      ChallengeLevelImport[]
    >([])

    const [dataObj, setDataObj] = useState<ChallengeLevelImport[]>([])
    const [alertOpen, setAlertOpen] = useState<boolean>(true)
    const [alertData, setAlertData] = useState({
      type: 'error' as AlertColor,
      message: '',
    })

    const uploadInputRef = useRef<HTMLInputElement>(null)

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()

      if (e && e.target && e.target.files && e.target.files.length > 0) {
        var files = e.target.files,
          f = files[0]
        var reader = new FileReader()
        reader.onload = function (e) {
          if (e && e.target) {
            var data = e.target.result
            let readedData = read(data, { type: 'binary' })
            const wsname = readedData.SheetNames[0]
            const ws = readedData.Sheets[wsname]

            /* Convert array to json*/
            const dataParse = utils.sheet_to_json<ChallengeLevelImport>(ws, {
              blankrows: false,
              defval: null,
            })
            setDataObj(dataParse)
          }
        }
        reader.readAsBinaryString(f)
        e.target.value = ''
      }
    }

    const exportToExcel = () => {
      const fileName = `${t('pages.challenge.import.exampleFilename')}.xlsx`
      // const headers = [
      //   [
      //     'User ID',
      //     'User Central ID',
      //     'User Internal ID',
      //     'Username',
      //     'Company',
      //     'Region',
      //     'Amator',
      //     'Ekspert',
      //     'Mistrz',
      //   ],
      // ]

      // //Had to create a new workbook and then add the header
      // const ws: XLSX.WorkSheet = XLSX.utils.book_new()
      // XLSX.utils.sheet_add_aoa(ws, headers)

      // //Starting in the second row to avoid overriding and skipping headers
      // XLSX.utils.sheet_add_json(ws, helperImportData, {
      //   origin: 'A2',
      //   skipHeader: true,
      // })

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(helperImportData)
      const wb: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        t('pages.challenge.import.exampleFilename'),
      )

      XLSX.writeFile(wb, fileName)
    }

    const saveImport = async () => {
      if (userChallengeLevelsImport) {
        try {
          setSavingImport(true)
          const importResponse =
            await ChallengeService.importUserChallengeLevels(
              userChallengeLevelsImport,
            )

          if (importResponse.data.success) {
            toast.success(
              t('messages.success.challengeLevelsImportSuccessful', {
                added: importResponse.data.addedUsers?.length,
                skipped: importResponse.data.skippedUsers?.length,
              }),
            )
          } else {
            toast.error(t('messages.error.generalError'))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setChallengeLevelsImport(null)
          setAlertOpen(false)
          setSavingImport(false)
        }
      }
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
          const userImportListResponse = await UserService.getUserImportList()

          if (userImportListResponse.data.users) {
            const formattedData = userImportListResponse.data.users.map(
              (user) => {
                return {
                  userId: user.userId,
                  userCentralId: user.userCentralId,
                  userInternalId: user.userInternalId,
                  username: user.username,
                  firstname: user.firstname,
                  lastname: user.lastname,
                  companyName: user.companyName,
                  regionName: user.regionName,
                  amateur: null,
                  expert: null,
                  master: null,
                }
              },
            )
            setHelperImportData(formattedData)
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setLoadingImport(false)
        }
      }
      fetchData()
    }, [t])

    useEffect(() => {
      let userChallengeLevelsData: ChallengeLevelsData[] = []
      var BreakException = {}
      let fileError = {
        type: 'error' as AlertColor,
        message: '',
      }

      setAlertOpen(false)
      setAlertData(fileError)
      setChallengeLevelsImport(null)

      try {
        dataObj.forEach((element, i) => {
          if (!element.hasOwnProperty('userId')) {
            fileError = {
              type: 'error',
              message: t('pages.challenge.import.messages.columnMissing', {
                column: 'userId',
                line: i + 1,
              }),
            }
            throw BreakException
          } else if (!element.hasOwnProperty('amateur')) {
            fileError = {
              type: 'error',
              message: t('pages.challenge.import.messages.columnMissing', {
                column: 'amateur',
                line: i + 1,
              }),
            }
            throw BreakException
          } else if (!element.hasOwnProperty('expert')) {
            fileError = {
              type: 'error',
              message: t('pages.challenge.import.messages.columnMissing', {
                column: 'expert',
                line: i + 1,
              }),
            }
            throw BreakException
          } else if (!element.hasOwnProperty('master')) {
            fileError = {
              type: 'error',
              message: t('pages.challenge.import.messages.columnMissing', {
                column: 'master',
                line: i + 1,
              }),
            }
            throw BreakException
          // } else if (
          //   (challengeLevels.amateur && !Number.isInteger(element.amateur)) ||
          //   (element.amateur && element.amateur < 0)
          // ) {
          //   fileError = {
          //     type: 'error',
          //     message: t('pages.challenge.import.messages.incorrectTarget', {
          //       level: t('pages.challenge.step3.amateur'),
          //       line: i + 2,
          //     }),
          //   }
          //   throw BreakException
          // } else if (
          //   (challengeLevels.expert && !Number.isInteger(element.expert)) ||
          //   (element.expert && element.expert < 0)
          // ) {
          //   fileError = {
          //     type: 'error',
          //     message: t('pages.challenge.import.messages.incorrectTarget', {
          //       level: t('pages.challenge.step3.expert'),
          //       line: i + 2,
          //     }),
          //   }
          //   throw BreakException
          // } else if (
          //   (challengeLevels.master && !Number.isInteger(element.master)) ||
          //   (element.master && element.master < 0)
          // ) {
          //   fileError = {
          //     type: 'error',
          //     message: t('pages.challenge.import.messages.incorrectTarget', {
          //       level: t('pages.challenge.step3.master'),
          //       line: i + 2,
          //     }),
          //   }
          //   throw BreakException
          } else {
            userChallengeLevelsData.push({
              userId: element.userId,
              amateur: challengeLevels.amateur ? element.amateur : null,
              expert: challengeLevels.expert ? element.expert : null,
              master: challengeLevels.master ? element.master : null,
            })
          }
        })
      } catch (e) {
        if (e !== BreakException) {
          throw e
        } else {
          userChallengeLevelsData = []
          setAlertOpen(true)
          setAlertData(fileError)
        }
      } finally {
        if (userChallengeLevelsData.length > 0 && challengeId) {
          setAlertOpen(true)
          setAlertData({
            type: 'info' as AlertColor,
            message: t('pages.challenge.import.messages.fileReadyToImport', {
              length: userChallengeLevelsData.length,
            }),
          })
          setChallengeLevelsImport({
            challengeId,
            data: userChallengeLevelsData,
          })
        }
      }
    }, [dataObj, challengeId, t, challengeLevels])

    return (
      <>
        {loadingImport && <LoadingSpinner />}
        {!loadingImport && (
          <div>
            <Box my={2}>
              <StyledLabel>{t('pages.challenge.import.title')}</StyledLabel>
              <PrimaryButton onClick={exportToExcel} size="small">
                {t('pages.challenge.import.downloadExampleXLSX')}
              </PrimaryButton>
            </Box>
            {!isImportPossible && (
              <Alert
                severity="info"
                sx={{ mt: 2 }}
                style={{ whiteSpace: 'pre-line' }}
              >
                {t('pages.challenge.import.messages.completeStep3Form')}
              </Alert>
            )}
            {isStep3Touched && isImportPossible && (
              <Alert
                severity="warning"
                sx={{ mt: 2 }}
                style={{ whiteSpace: 'pre-line' }}
              >
                {t('pages.challenge.import.messages.unsavedStep3Form')}
              </Alert>
            )}
            {isImportPossible && !isStep3Touched && (
              <>
                <Input
                  ref={uploadInputRef}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={onFileChange}
                />
                <label htmlFor="contained-button-file">
                  <SecondaryButton
                    onClick={() => {
                      if (challengeId) {
                        uploadInputRef.current && uploadInputRef.current.click()
                      }
                    }}
                  >
                    {t('pages.challenge.import.selectFile')}
                  </SecondaryButton>
                </label>
                <Collapse in={alertOpen}>
                  <Alert
                    severity={alertData.type}
                    sx={{ mt: 2 }}
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {alertData.message}
                  </Alert>
                </Collapse>
                {userChallengeLevelsImport && (
                  <Stack
                    className="buttons-container"
                    spacing={2}
                    direction="row"
                    justifyContent="flex-end"
                    width="100%"
                  >
                    <PrimaryButton
                      onClick={saveImport}
                      disabled={savingImport || !challengeId}
                    >
                      {t('common.import')}
                    </PrimaryButton>
                  </Stack>
                )}
              </>
            )}
          </div>
        )}
      </>
    )
  }

export default ChallengeImportForm
