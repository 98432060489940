import { User } from '../store/Auth/types'

export default function authMessengerHeader() {
  const user: User = JSON.parse(localStorage.getItem('user') || '')

  if (user && user.messengerToken) {
    return { Authorization: 'Bearer ' + user.messengerToken }
  } else {
    return {}
  }
}
