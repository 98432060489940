import axios from 'axios'
import {
  PlanDetailsReponse,
  PlanListReponse,
  PlanTypesResponse,
} from '../store/Plan/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getPlanList = (): Promise<PlanListReponse> => {
  return axios.get(API_URL + '/api/admin/plan', {
    headers: authHeader(),
  })
}

const getPlanTypeList = (): Promise<PlanTypesResponse> => {
  return axios.get(API_URL + '/api/admin/plan/type', {
    headers: authHeader(),
  })
}

const getPlanDetails = (planId: number): Promise<PlanDetailsReponse> => {
  return axios.get(API_URL + `/api/admin/plan/${planId}`, {
    headers: authHeader(),
  })
}

const exportedObject = {
  getPlanList,
  getPlanDetails,
  getPlanTypeList,
}

export default exportedObject
