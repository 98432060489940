import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'
import { Grid } from '@mui/material'
import PeriodPlanResult from './PeriodPlanResult/PeriodPlanResult'
import PeriodRegionPlanChart from './PeriodRegionPlanChart/PeriodRegionPlanChart'
import UsersRanking from './UsersRanking/UsersRanking'
// import ChallengesStatistics from './ChallengesStatistics/ChallengesStatistics'

const Home = () => {
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  const colors = [
    '#4e79a7',
    '#f28e2c',
    '#e15759',
    '#76b7b2',
    '#59a14f',
    '#edc949',
    '#af7aa1',
    '#ff9da7',
    '#9c755f',
    '#bab0ab',
  ]

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} mb={2}>
          <UsersRanking />
        </Grid>
        <Grid item xs={12} mb={2}>
          <PeriodPlanResult colors={colors} />
        </Grid>
        <Grid item xs={12} mb={2}>
          <PeriodRegionPlanChart colors={colors} />
        </Grid>
        {/* <Grid item xs={12} mb={2}>
          <ChallengesStatistics />
        </Grid> */}
      </Grid>
    </>
  )
}

export default Home
