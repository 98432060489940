import React, { FunctionComponent, useState } from 'react'

import MessengerService from '../../../services/messenger.service'
import { GroupParticipantUserId } from '../../../store/Messenger/types'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import {
  Typography,
  Alert,
  TextField,
  Avatar,
  IconButton,
  Button,
  Divider,
} from '@mui/material'
import { getBase64, odmiana } from '../../../helpers/utils'
import DeleteIcon from '@mui/icons-material/Delete'

const Input = styled('input')({
  display: 'none',
})
const GroupImageName = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '200px',
  textOverflow: 'ellipsis',
  textDecoration: 'underline',
})
const GroupImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px',
})

type MessengerAddGroupProps = {
  selectedUsers: number[]
  onGroupCreationCancel: () => void
  onGroupCreationSuccess: () => void
  channelId: number
}

const MessengerAddGroup: FunctionComponent<MessengerAddGroupProps> = ({
  selectedUsers,
  onGroupCreationCancel,
  onGroupCreationSuccess,
  channelId,
}) => {
  const { t } = useTranslation()
  const [groupName, setGroupName] = useState<string>('')
  const [groupImage, setGroupImage] = useState<File | null>(null)
  const [groupImageBase64, setGroupImageBase64] = useState<string>('')
  const [errors, setErrors] = useState<string[]>([])
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const handleGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setGroupName(event.target.value)
  }

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      setGroupImage(event.target.files[0] as File)
      const data = await getBase64(event.target.files[0])

      if (typeof data === 'string') {
        setGroupImageBase64(data)
      }
      event.target.value = ''
    }
  }

  const createGroup = async () => {
    setIsCreating(true)
    setErrors([])
    const users: GroupParticipantUserId[] = []
    const iconFilename = groupImage ? groupImage.name : null
    const iconContent = groupImage ? groupImageBase64.split(',')[1] : null
    const formErrors: string[] = []

    if (groupName === '') {
      formErrors.push(t('messenger.errors.groupNameRequired') as string)
    }
    if (selectedUsers.length === 0) {
      formErrors.push(t('messenger.errors.groupUsersRequired') as string)
    }

    if (formErrors.length > 0) {
      setErrors(formErrors)
      setIsCreating(false)
      return
    }

    selectedUsers.forEach((user) =>
      users.push({
        userId: user,
      }),
    )

    try {
      const createGroupResponse =
        await MessengerService.createGroupConversation(
          channelId,
          groupName,
          iconFilename,
          iconContent,
          users,
        )
      console.log(createGroupResponse)
    } catch (error) {
      const _content =
        // (error.response && error.response.data) ||
        (error as Error).message || (error as Error).toString()

      console.warn(_content)
      // TODO: fix detail error
      // setErrors([_content.detail])
      setErrors([_content])
    } finally {
      setIsCreating(false)
      onGroupCreationSuccess()
    }
  }

  return (
    <>
      <div
        style={{
          height: '105px',
          backgroundColor: '#e6e6e6',
          padding: '10px 20px',
          color: '#595f6f',
        }}
      >
        <Typography variant="h6">{t('messenger.addGroup.title')}</Typography>
      </div>
      <div style={{ padding: '30px' }}>
        {errors.length > 0 && (
          <Alert severity="error" style={{ marginBottom: '25px' }}>
            {errors.map((error) => (
              <>
                {error}
                <br />
              </>
            ))}
          </Alert>
        )}
        <TextField
          id="group-name"
          label={t('messenger.addGroup.groupName')}
          variant="outlined"
          value={groupName}
          size="small"
          fullWidth
          onChange={handleGroupNameChange}
        />
        <Typography variant="subtitle2" style={{ margin: '35px 0 15px 0' }}>
          {t('messenger.addGroup.groupIcon')}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            style={{ width: '70px', height: '70px' }}
            src={groupImageBase64}
          />
          <div style={{ marginLeft: '20px' }}>
            <Typography variant="caption" gutterBottom display="block">
              {t('messenger.addGroup.uploadIcon')}
            </Typography>
            {groupImage && (
              <GroupImageContainer>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    setGroupImage(null)
                    setGroupImageBase64('')
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>{' '}
                <GroupImageName variant="caption">
                  {groupImage.name}
                </GroupImageName>
              </GroupImageContainer>
            )}
            <Input
              id="outlined-button-file"
              type="file"
              onChange={onFileChange}
              accept="image/*"
            />
            <label htmlFor="outlined-button-file">
              <Button
                variant="outlined"
                component="span"
                size="small"
                color="primary"
                style={{ textTransform: 'capitalize' }}
              >
                {t('messenger.addGroup.browse')}
              </Button>
            </label>
          </div>
        </div>
        <Typography variant="subtitle2" style={{ marginTop: '35px' }}>
          {t('messenger.addGroup.groupUsers')}
        </Typography>
        <Typography
          variant="h5"
          style={{ marginBottom: '25px', fontWeight: 'bold' }}
        >
          {selectedUsers.length}{' '}
          {odmiana(
            selectedUsers.length,
            t('messenger.person'),
            t('messenger.persons'),
            t('messenger.persons2'),
          )}
        </Typography>
        <Divider />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={onGroupCreationCancel}
          >
            {t('messenger.addGroup.cancel')}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={createGroup}
            disabled={isCreating}
          >
            {t('messenger.addGroup.save')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default MessengerAddGroup
