import React from 'react'
import {
  Column,
  useBlockLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'

import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useSticky } from 'react-table-sticky'
import { useTranslation } from 'react-i18next'

import './Table.scss'
import { Stack, FormControl, Select, MenuItem, Pagination } from '@mui/material'

interface TableProps {
  columns: Array<Column<object>>
  data: Array<object>
  height: string
}

const Table: React.FC<TableProps> = ({ columns, data, height }) => {
  const { t } = useTranslation()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: {
        pageSize: 100,
      },
    },
    useSortBy,
    useBlockLayout,
    useSticky,
    usePagination,
  )

  const getPlanCellColor = (cell: any) => {
    const colorId = cell.column.id.split('--')[1]
    if (colorId) {
      const color =
        cell.row.original.planResults[colorId] &&
        cell.row.original.planResults[colorId].colorHexValue
      if (color) {
        return { backgroundColor: `${color}15` }
      }
    }
    return {}
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    gotoPage(value - 1)
  }

  // Render the UI for your table
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'inline-block',
          maxWidth: '100%',
          overflow: 'scroll',
          height,
          border: '1px solid #EAEAEA',
        }}
      >
        <MuiTable {...getTableProps()} className="table sticky">
          <TableHead className="header">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardOutlinedIcon
                            sx={{ fontSize: 15, marginLeft: '5px' }}
                            color="disabled"
                          />
                        ) : (
                          <ArrowUpwardOutlinedIcon
                            sx={{ fontSize: 15, marginLeft: '5px' }}
                            color="disabled"
                          />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <TableCell
                        {...cell.getCellProps({
                          style: getPlanCellColor(cell),
                        })}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
            {page.length === 0 && (
              <TableRow>
                <TableCell>{t('common.noData')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </div>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        style={{
          backgroundColor: '#DCDCDC',
          marginTop: '-6px',
          padding: '3px 10px',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl size="small">
            <Select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[20, 50, 100, 200, 500].map((pageSize) => (
                <MenuItem key={pageSize} value={pageSize}>
                  Pokaż {pageSize}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              page={pageIndex + 1}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default Table
