import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { IconButton, SelectChangeEvent, Stack } from '@mui/material'
import PeriodService from '../../../../services/period.service'
import StorePlanService from '../../../../services/storePlan.service'
import { useTranslation } from 'react-i18next'
import StorePlansToolbar from '../partials/StorePlansToolbar'
import {
  escapeRegExp,
  handleNavigationClick,
  thousandsSeparator,
} from '../../../../helpers/utils'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import {
  StorePlan,
  StorePlanData,
  StorePlanResult,
} from '../../../../store/StorePlan/types'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import StorePlanDialog from '../partials/StorePlanDialog'
import { errorHandler } from '../../../../helpers/errorHandler'
import { useParams } from 'react-router-dom'
import { ReactComponent as SetPlansIcon } from '../../../../assets/images/icons/set_plans.svg'
import { Column } from 'react-table'
import Table from '../../../Table/Table'

type StorePlansListProps = {
  path: string
}

interface Legend extends StorePlanResult {
  visible: boolean
}

interface ParamTypes {
  userId: string
  periodId: string
}

const StorePlansList: FunctionComponent<StorePlansListProps> = ({ path }) => {
  const { t } = useTranslation()
  let { userId, periodId } = useParams<ParamTypes>()
  const [loading, setLoading] = useState<boolean>(true)
  const [legend, setLegend] = useState<Legend[]>([])
  const [storePlansList, setStorePlansList] = useState<StorePlan[]>([])
  const [storePlanData, setStorePlanData] = useState<StorePlanData | null>(null)
  const [filteredStorePlansList, setFilteredStorePlansList] = useState<
    StorePlan[]
  >([])
  const [searchText, setSearchText] = useState<string>('')
  const [periodValue, setPeriodValue] = useState<string>('')
  const [idType, setIdType] = useState<string>('id')
  const [storeId, setStoreId] = useState<number | null>(null)
  const [periods, setPeriods] = useState<Period[]>([])
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [openStorePlanDialog, setStorePlanDialogOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const handleStorePlanDialogClickOpen = (storeId: number) => {
    setStoreId(storeId)
    setStorePlanDialogOpen(true)
  }

  const handleStorePlanDialogClose = (refreshTable: boolean = false) => {
    setStorePlanDialogOpen(false)
    setRefresh(refreshTable)
  }

  const requestSearch = (searchValue: string) => {
    let filteredRows: StorePlan[] = storePlansList

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    filteredRows = filteredRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && searchRegex.test(row[field].toString())
      })
    })
    setFilteredStorePlansList(filteredRows)
  }

  const generateTableColumns = useCallback(
    (storePlans: StorePlan[]) => {
      const columns = []
      columns.push({
        Header: ' ',
        sticky: 'left',
        columns: [
          {
            accessor: 'edit',
            Header: '',
            width: 55,
            disableSortBy: true,
            Cell: (params: any) => (
              <IconButton
                onClick={() =>
                  handleStorePlanDialogClickOpen(params.row.values.id)
                }
                size="small"
                style={{ padding: 0 }}
              >
                <SetPlansIcon />
              </IconButton>
            ),
          },
          {
            accessor:
              idType === 'internalId'
                ? 'internalId'
                : idType === 'centralId'
                ? 'centralId'
                : 'id',
            Header: t('pages.storePlans.table.id').toString(),
            width: idType === 'id' ? 70 : 70,
            Cell: (params: any) => {
              if (idType === 'internalId') {
                return params.row.values.internalId
              } else if (idType === 'centralId') {
                return params.row.values.centralId
              } else {
                return params.row.values.id
              }
            },
          },
          {
            accessor: 'name',
            Header: t('pages.storePlans.table.name').toString(),
            width: 320,
          },
          {
            accessor: 'city',
            Header: t('pages.storePlans.table.city').toString(),
            width: 150,
          },
        ],
      })

      storePlans.forEach((storePlan, j) =>
        storePlan.planResults.forEach((planResult, i) => {
          if (j === 0) {
            columns.push({
              Header: planResult.name,
              columns: [
                {
                  id: `${t('pages.storePlans.table.planValue')}--${i}`,
                  Header: t('pages.storePlans.table.planValue').toString(),
                  accessor: (data: any) => {
                    return data.planResults[i].planValue
                  },
                  width: 90,
                  Cell: (params: any) => (
                    <div style={{ width: '100%', textAlign: 'right' }}>
                      {thousandsSeparator(params.value)}
                    </div>
                  ),
                },
                {
                  id: `${t('pages.storePlans.table.resultValue')}--${i}`,
                  Header: t('pages.storePlans.table.resultValue').toString(),
                  accessor: (data: any) => {
                    return data.planResults[i].resultValue
                  },
                  width: 90,
                  Cell: (params: any) => (
                    <div style={{ width: '100%', textAlign: 'right' }}>
                      {thousandsSeparator(params.value)}
                    </div>
                  ),
                },
                {
                  id: `${t('pages.storePlans.table.resultPercentValue')}--${i}`,
                  Header: t(
                    'pages.storePlans.table.resultPercentValue',
                  ).toString(),
                  accessor: (data: any) => {
                    return data.planResults[i].resultPercentValue
                  },
                  width: 100,
                  Cell: (params: any) => (
                    <div style={{ width: '100%', textAlign: 'right' }}>
                      {thousandsSeparator(params.value)}%
                    </div>
                  ),
                },
              ],
            })
          }
        }),
      )
      return columns
    },
    [idType, t],
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const periodListResponse = await PeriodService.getPeriodList()

        if (periodListResponse.data.periodList) {
          setPeriods(periodListResponse.data.periodList)

          setPeriodValue(periodId)
          const storePlansResponse = await StorePlanService.getStorePlans(
            parseInt(periodId),
            parseInt(userId),
          )

          if (storePlansResponse.data.storePlans) {
            setTableColumns(
              generateTableColumns(storePlansResponse.data.storePlans),
            )

            setStorePlansList(storePlansResponse.data.storePlans)
            setFilteredStorePlansList(storePlansResponse.data.storePlans)
            setStorePlanData(storePlansResponse.data)

            if (storePlansResponse.data.storePlans.length > 0) {
              setLegend(
                storePlansResponse.data.storePlans[0].planResults.map(
                  (plan) => {
                    return {
                      ...plan,
                      visible: true,
                    }
                  },
                ),
              )
            } else {
              setLegend([])
            }
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [path, t, periodId, userId, generateTableColumns])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storePlansResponse = await StorePlanService.getStorePlans(
          parseInt(periodValue),
          parseInt(userId),
        )

        if (storePlansResponse.data.storePlans) {
          setTableColumns(
            generateTableColumns(storePlansResponse.data.storePlans),
          )

          setStorePlansList(storePlansResponse.data.storePlans)
          setFilteredStorePlansList(storePlansResponse.data.storePlans)

          if (storePlansResponse.data.storePlans.length > 0) {
            setLegend(
              storePlansResponse.data.storePlans[0].planResults.map((plan) => {
                return {
                  ...plan,
                  visible: true,
                }
              }),
            )
          } else {
            setLegend([])
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (periodValue !== '') {
      setSearchText('')
      fetchData()
    }
  }, [periodValue, t, refresh, idType, userId, generateTableColumns])

  const downloadXLSX = (data: StorePlan[], name: string) => {
    // prepare data for export
    const preparedData = data.map((d) => {
      const dataToReturn: any = {
        id: (d as any)[idType],
        name: d.name,
        city: d.city,
      }
      d.planResults.forEach((plan) => {
        dataToReturn[`${plan.name} planValue`] = Number(plan.planValue)
        dataToReturn[`${plan.name} resultValue`] = Number(plan.resultValue)
        dataToReturn[`${plan.name} resultPercentValue`] =
          Number(plan.resultPercentValue)
      })
      return dataToReturn
    })
    const fileName = `${name}.xlsx`
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(preparedData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, name)
    XLSX.writeFile(wb, fileName)
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            width="100%"
          >
            <Stack flexDirection="row" alignItems="center">
              <SecondaryButton
                variant="contained"
                onClick={() => handleNavigationClick('user-plans')}
              >
                {t('common.back')}
              </SecondaryButton>
              <Stack
                flexDirection="column"
                lineHeight="normal"
                fontSize="0.8em"
                pl="15px"
              >
                <strong>{t('pages.storePlans.id')}:</strong>
                {storePlanData?.id}
              </Stack>
              <Stack
                flexDirection="column"
                lineHeight="normal"
                fontSize="0.8em"
                pl="15px"
              >
                <strong>{t('pages.storePlans.username')}:</strong>
                {storePlanData?.username}
              </Stack>
              <Stack
                flexDirection="column"
                lineHeight="normal"
                fontSize="0.8em"
                pl="15px"
              >
                <strong>{t('pages.storePlans.regionName')}:</strong>
                {storePlanData?.regionName}
              </Stack>
              <Stack
                flexDirection="column"
                lineHeight="normal"
                fontSize="0.8em"
                pl="15px"
              >
                <strong>{t('pages.storePlans.companyName')}:</strong>
                {storePlanData?.companyName}
              </Stack>
              <Stack
                flexDirection="column"
                lineHeight="normal"
                fontSize="0.8em"
                pl="15px"
              >
                <strong>{t('pages.storePlans.storesAmount')}:</strong>
                {storePlanData?.storesAmount}
              </Stack>
            </Stack>
            <SecondaryButton
              variant="contained"
              onClick={() =>
                downloadXLSX(filteredStorePlansList, 'store-plans')
              }
            >
              {t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <StorePlansToolbar
            periods={periods}
            value={searchText}
            legend={legend}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              requestSearch(event.target.value)
            }}
            periodValue={periodValue}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
              requestSearch(searchText)
            }}
            idType={idType}
            filterIdType={(event: SelectChangeEvent) => {
              setIdType(event.target.value)
            }}
            clearSearch={() => {
              setSearchText('')
              requestSearch('')
            }}
            togglePlanVisibility={(planId: number) => {
              let updatedLegend = legend.map((el) => {
                if (el.id === planId) {
                  el.visible = !el.visible
                }
                return el
              })
              setLegend(updatedLegend)
              const filteredStorePlansListWithVisibility = storePlansList.map(
                (storePlan) => {
                  return {
                    ...storePlan,
                    planResults: storePlan.planResults.filter((planResult) =>
                      legend
                        .filter((l) => l.visible)
                        .map((l2) => l2.id)
                        .includes(planResult.id),
                    ),
                  }
                },
              )
              setFilteredStorePlansList(filteredStorePlansListWithVisibility)

              setTableColumns(
                generateTableColumns(filteredStorePlansListWithVisibility),
              )
            }}
          />
          <Table
            columns={tableColumns}
            data={filteredStorePlansList}
            height="calc(100vh - 300px)"
          />
          {storeId && (
            <StorePlanDialog
              open={openStorePlanDialog}
              handleClose={handleStorePlanDialogClose}
              periodId={parseInt(periodValue)}
              storeId={storeId}
            />
          )}
        </>
      )}
    </>
  )
}

export default StorePlansList
