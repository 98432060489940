import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'

import { AlertColor, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

import UserPlanImportStep1 from './partials/UserPlanImportStep1'
import UserPlanImportStep2 from './partials/UserPlanImportStep2'
import UserPlanImportStep3 from './partials/UserPlanImportStep3'

const UserPlanImportContainer = styled('div')(({ theme }) => ({
  'width-100': {
    width: '100%',
  },
  '.header': {
    fontSize: '1.25rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
    '&_number': {
      backgroundColor: theme.colorsPalette.gray.gray5,
      color: '#FFF',
      width: 46,
      height: 46,
      borderRadius: 23,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&_txt': {
      color: theme.colorsPalette.gray.gray5,
      fontWeight: 'bold',
      marginLeft: 10,
    },
  },
  '.challenges-main-grid_item': {
    paddingRight: 16,
    '&:not(:first-of-type)': {
      borderLeft: '1px solid',
      borderColor: theme.colorsPalette.gray.gray3,
    },
  },
}))

const UserPlanImport = () => {
  const { t } = useTranslation()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  const [plan, setPlan] = useState<string>('')

  const [acceptMessage, setAcceptMessage] = useState<{
    type: AlertColor
    message: string
  } | null>(null)
  const [skippedStores, setSkippedStores] = useState<
    { storeCentralId: string }[]
  >([])
  const [addedStores, setAddedStores] = useState<{ storeCentralId: string }[]>(
    [],
  )

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <div style={{ padding: '0 20px' }}>
      <UserPlanImportContainer>
        <Grid className="challenges-main-grid" container spacing={2}>
          <Grid className="challenges-main-grid_item" item md={4} xs={12}>
            <div className="header">
              <div className="header_number">1</div>
              <div className="header_txt">
                {t('pages.userPlanImport.step1.title')}
              </div>
            </div>
            <UserPlanImportStep1
              plan={plan}
              handlePlanChange={(e) => setPlan(e.target.value)}
            />
          </Grid>
          <Grid className="challenges-main-grid_item" item md={4} xs={12}>
            <div className="header">
              <div className="header_number">2</div>
              <div className="header_txt">
                {t('pages.userPlanImport.step2.title')}
              </div>
            </div>
            <UserPlanImportStep2
              plan={plan}
              handleSetAcceptMessage={setAcceptMessage}
              handleSetAddedStores={setAddedStores}
              handleSetSkippedStores={setSkippedStores}
              handleCancelImport={() => setPlan('')}
            />
          </Grid>
          <Grid className="challenges-main-grid_item" item md={4} xs={12}>
            <div className="header">
              <div className="header_number">3</div>
              <div className="header_txt">
                {t('pages.userPlanImport.step3.title')}
              </div>
            </div>
            <UserPlanImportStep3
              plan={plan}
              acceptMessage={acceptMessage}
              addedStores={addedStores}
              skippedStores={skippedStores}
              handleSetAcceptMessage={setAcceptMessage}
              handleSetAddedStores={setAddedStores}
              handleSetSkippedStores={setSkippedStores}
              handleCancelImport={() => setPlan('')}
            />
          </Grid>
        </Grid>
      </UserPlanImportContainer>
    </div>
  )
}

export default UserPlanImport
