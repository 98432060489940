import axios from 'axios'
import {
  ChallengeListResponse,
  ChallengeStateListResponse,
  ChallengeStep1Response,
  ChallengeStep1RequestParams,
  ChallengeStep2Response,
  ChallengeStep2RequestParams,
  ChallengeStep3RequestParams,
  ChallengeStep3Response,
  ChallengeUnitListResponse,
  ChallengeDetailsResponse,
  ChallengeLevelsImportParams,
  ChallengeLevelsImportResponse,
  ChallengeResultsImportParams,
  ChallengeResultsImportResponse,
} from '../store/Challenge/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getChallengeDetails = (
  challengeId: number,
): Promise<ChallengeDetailsResponse> => {
  return axios.get(API_URL + `/api/admin/challenge/details/${challengeId}`, {
    headers: authHeader(),
  })
}

const getChallengeList = (): Promise<ChallengeListResponse> => {
  return axios.get(API_URL + '/api/admin/challenge', {
    headers: authHeader(),
  })
}

const getChallengeStateList = (): Promise<ChallengeStateListResponse> => {
  return axios.get(API_URL + '/api/admin/challenge/state', {
    headers: authHeader(),
  })
}

const getChallengeUnitList = (): Promise<ChallengeUnitListResponse> => {
  return axios.get(API_URL + '/api/admin/challenge/unit', {
    headers: authHeader(),
  })
}

const createOrUpdateChallengeStep1 = async (
  params: ChallengeStep1RequestParams,
): Promise<ChallengeStep1Response> => {
  return await axios.post(API_URL + '/api/admin/challenge', params, {
    headers: authHeader(),
  })
}

const updateChallengeStep2 = async (
  params: ChallengeStep2RequestParams,
): Promise<ChallengeStep2Response> => {
  return await axios.post(API_URL + '/api/admin/challenge/details', params, {
    headers: authHeader(),
  })
}

const updateChallengeStep3 = async (
  params: ChallengeStep3RequestParams,
): Promise<ChallengeStep3Response> => {
  return await axios.post(API_URL + '/api/admin/challenge/points', params, {
    headers: authHeader(),
  })
}

const importUserChallengeLevels = async (
  params: ChallengeLevelsImportParams,
): Promise<ChallengeLevelsImportResponse> => {
  return await axios.post(
    API_URL + '/api/admin/challenge/import/user-challenge-target',
    params,
    {
      headers: authHeader(),
    },
  )
}

const importUserChallengeResults = async (
  params: ChallengeResultsImportParams,
): Promise<ChallengeResultsImportResponse> => {
  return await axios.post(
    API_URL + '/api/admin/challenge/import/user-challenge-result',
    params,
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getChallengeDetails,
  getChallengeList,
  getChallengeStateList,
  getChallengeUnitList,
  createOrUpdateChallengeStep1,
  updateChallengeStep2,
  updateChallengeStep3,
  importUserChallengeLevels,
  importUserChallengeResults,
}

export default exportedObject
