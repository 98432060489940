import axios from 'axios'
import { RegionListReponse } from '../store/Region/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getRegionList = (): Promise<RegionListReponse> => {
  return axios.get(API_URL + '/api/admin/region', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getRegionList,
}

export default exportedObject
