import React, { FunctionComponent, useEffect, useState } from 'react'
import MessengerService from '../../../services/messenger.service'
import { Region, User } from '../../../store/Messenger/types'
import {
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemAvatar,
  Avatar,
  ListItemText,
  FormGroup,
  FormControlLabel,
  List,
} from '@mui/material'
import LoadingSpinner from '../../shared/LoadingSpinner'

type MessengerUsersSelectionProps = {
  selectedUsers: number[]
  onSelectedUsers: (users: number[]) => void
  search: string
  regionView: boolean
}

const MessengerUsersSelection: FunctionComponent<
  MessengerUsersSelectionProps
> = ({ selectedUsers, onSelectedUsers, search, regionView }) => {
  const [users, setUsers] = useState<User[]>([])
  const [regions, setRegions] = useState<Region[]>([])
  const [usersFiltered, setUsersFiltered] = useState<User[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [checkedUsers, setCheckedUsers] = useState<number[]>([])
  const [filterType, setFilterType] = React.useState({
    rks: true,
    ph: true,
  })

  const handleFilterTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilterType({
      ...filterType,
      [event.target.name]: event.target.checked,
    })
  }

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userListResponse = await MessengerService.getUserList()
        setUsers(userListResponse.data.userList)
        setUsersFiltered(userListResponse.data.userList)

        userListResponse.data.userList.forEach((user) => {
          setRegions((prevRegions) => {
            const foundRegion = prevRegions.find(
              (region) => region.regionId === user.regionId,
            )
            if (!foundRegion) {
              return [
                ...prevRegions,
                { regionId: user.regionId, regionName: user.regionName },
              ]
            }
            return [...prevRegions]
          })
        })

        // sort by regionName
        setRegions((prevRegions) =>
          prevRegions.sort((a, b) =>
            (a.regionName ? a.regionName : '') >
            (b.regionName ? b.regionName : '')
              ? 1
              : (b.regionName ? b.regionName : '') >
                (a.regionName ? a.regionName : '')
              ? -1
              : 0,
          ),
        )
      } catch (error) {
        const _content =
          // (error.response && error.response.data) ||
          (error as Error).message || (error as Error).toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }
    fetchUsers()
  }, [])

  useEffect(() => {
    if (search !== '') {
      let filteredUsers: User[] = []
      if (search.includes(',')) {
        filteredUsers = users.filter((user) =>
          search.replace(/ /g, '').split(',').includes(user.id.toString()),
        )
      } else {
        filteredUsers = users.filter(
          (user) =>
            user.id === parseInt(search) ||
            user.firstname?.toLowerCase().includes(search.toLowerCase()) ||
            user.lastname?.toLowerCase().includes(search.toLowerCase()) ||
            `${user.firstname} ${user.lastname}`
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            `${user.lastname} ${user.firstname}`
              ?.toLowerCase()
              .includes(search.toLowerCase()),
        )
      }
      setUsersFiltered(filteredUsers)
    } else {
      setUsersFiltered(users)
    }

    // TODO: refactor based on API values
    setUsersFiltered((prevState) =>
      prevState.filter((user) => {
        if (filterType.ph && user.userTypeName === 'PH') {
          return user
        }
        if (filterType.rks && user.userTypeName === 'RKS') {
          return user
        }
        return null
      }),
    )
  }, [search, users, filterType])

  useEffect(() => {
    setCheckedUsers(selectedUsers)
  }, [selectedUsers])

  const handleToggle = (value: number) => () => {
    const currentIndex = checkedUsers.indexOf(value)
    const newChecked = [...checkedUsers]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setCheckedUsers(newChecked)
    onSelectedUsers(newChecked)
  }

  const renderUserItem = (
    userId: number,
    firstname: string | null,
    lastname: string | null,
    isAsm: boolean,
    iconMimeType: string | null,
    iconContent: string | null,
  ) => {
    const labelId = `checkbox-list-label-${userId}`

    return (
      <ListItem
        key={userId}
        button
        style={{
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        onClick={handleToggle(userId)}
      >
        <ListItemIcon style={{ minWidth: '30px' }}>
          <Checkbox
            edge="start"
            checked={checkedUsers.indexOf(userId) !== -1}
            tabIndex={-1}
            disableRipple
            color="primary"
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemAvatar>
          <Avatar
            style={{
              border: isAsm ? '1px solid #d70000' : '1px solid #e0e4e7',
            }}
            src={`data:${iconMimeType};base64,${iconContent}`}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <span>
              {userId} {firstname} {lastname}
            </span>
          }
        />
      </ListItem>
    )
  }

  return (
    <>
      <FormGroup row style={{ paddingLeft: '16px' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={filterType.rks}
              onChange={handleFilterTypeChange}
              name="rks"
              color="primary"
            />
          }
          label="RKS"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filterType.ph}
              onChange={handleFilterTypeChange}
              name="ph"
              color="primary"
            />
          }
          label="PH"
        />
      </FormGroup>
      <List
        style={{
          height: 'calc(100% - 237px - 42px)', // 42px - filters height
          overflowY: 'auto',
        }}
      >
        {loading && <LoadingSpinner />}
        {!loading &&
          !regionView &&
          usersFiltered.map((user) => {
            return renderUserItem(
              user.id,
              user.firstname,
              user.lastname,
              user.userTypeName === 'RKS',
              user.imageMimeType,
              user.imageContent,
            )
          })}
        {!loading &&
          regionView &&
          regions.map((region) => (
            <>
              {region.regionId && (
                <div
                  style={{
                    width: 'calc(100% - 32px)',
                    borderBottom: '1px solid #ccc',
                    lineHeight: '0.1em',
                    margin: '20px 16px 10px',
                    fontSize: '16px',
                  }}
                >
                  <span
                    style={{
                      background: '#fff',
                      paddingRight: '10px',
                    }}
                  >
                    {region.regionName}
                  </span>
                </div>
              )}
              <div>
                {usersFiltered.map((user) => {
                  if (user.regionId === region.regionId) {
                    return renderUserItem(
                      user.id,
                      user.firstname,
                      user.lastname,
                      user.userTypeName === 'RKS',
                      user.imageMimeType,
                      user.imageContent,
                    )
                  }
                  return null
                })}
              </div>
            </>
          ))}
      </List>
    </>
  )
}

export default MessengerUsersSelection
