import { User } from '../store/Auth/types'

export const isSuperAdmin = (user: User) =>
  user.roles.includes('ROLE_SUPER_ADMIN')

export const isCentralAdmin = (user: User) =>
  user.roles.includes('ROLE_CENTRAL_ADMIN')

export const isCompanyAdmin = (user: User) =>
  user.roles.includes('ROLE_COMPANY_ADMIN')
