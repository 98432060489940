import React, { FunctionComponent, useEffect, useState } from 'react'
import { IconButton, SelectChangeEvent, Tooltip } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import PeriodService from '../../../../services/period.service'
import ChallengeService from '../../../../services/challenge.service'
import { Challenge, ChallengeState } from '../../../../store/Challenge/types'
import { useTranslation } from 'react-i18next'
import ChallengesToolbar from '../partials/ChallengesToolbar'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { escapeRegExp, handleNavigationClick } from '../../../../helpers/utils'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { styled } from '@mui/material/styles'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import { ReactComponent as ChallengeImportResultsIcon } from '../../../../assets/images/icons/challenge_import_results.svg'
import moment from 'moment'
import ChallengeResultsImportDialog from '../partials/ChallengeResultsImportDialog'

const DataGridTable = styled(DataGrid)(({ theme }) => ({
  '&.MuiDataGrid-root': {
    border: '0px',
  },
  '.MuiCheckbox-root': {
    width: '15px',
    height: '15px',
    borderRadius: '0px',
    padding: '0px',
    backgroundColor: '#FFF',
    color: theme.colorsPalette.gray.gray3,
    '&:input': {},
  },
  '.MuiDataGrid-columnHeader': {
    '&:not(:last-of-type)': {
      borderRight: '1px solid #FFF',
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0px',
    },
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
  '.MuiDataGrid-columnsContainer': {
    '.MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: theme.colorsPalette.gray.gray3,
      borderRadius: '5px 5px 0px 0px',
    },
  },
  '.MuiDataGrid-row': {
    '&:nth-of-type(even)': {
      backgroundColor: theme.colorsPalette.gray.gray1,
    },
    '.MuiDataGrid-cell': {
      borderBottom: 'none',
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
      borderRightColor: theme.colorsPalette.gray.gray2,
      '&:first-of-type': {
        borderLeftWidth: '1px',
        borderLeftStyle: 'solid',
        borderLeftColor: theme.colorsPalette.gray.gray2,
      },
    },
  },
}))

type ChallengeListProps = {
  path: string
}

const ChallengeList: FunctionComponent<ChallengeListProps> = ({ path }) => {
  const { t } = useTranslation()
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [loading, setLoading] = useState<boolean>(true)
  const [challengeList, setChallengeList] = useState<Challenge[]>([])
  const [filteredChallengeList, setFilteredChallengeList] = useState<
    Challenge[]
  >([])
  const [searchText, setSearchText] = useState<string>('')
  const [periodValue, setPeriodValue] = useState<string>('all')
  const [periods, setPeriods] = useState<Period[]>([])
  const [statusValue, setStatusValue] = useState<string>('all')
  const [statuses, setStatuses] = useState<ChallengeState[]>([])
  const [challengeId, setChallengeId] = useState<number | null>(null)
  const [
    challengeResultsImportDialogOpen,
    setChallengeResultsImportDialogOpen,
  ] = useState<boolean>(false)

  const handleChallengeResultsImportDialogClickOpen = (challengeId: number) => {
    setChallengeId(challengeId)
    setChallengeResultsImportDialogOpen(true)
  }

  const handleChallengeResultsImportDialogClose = () => {
    setChallengeResultsImportDialogOpen(false)
  }

  const requestSearch = (
    searchValue: string,
    filters: { column: string; value: string }[],
  ) => {
    let filteredRows: Challenge[] = challengeList

    if (filters.length > 0) {
      filters.forEach((filter) => {
        filteredRows = filteredRows.filter((challenge) => {
          if (filter.value === 'all') {
            return true
          }
          if (
            (challenge as any)[filter.column].toString() ===
            filter.value.toString()
          ) {
            return true
          }
          return false
        })
      })
    }

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    filteredRows = filteredRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && searchRegex.test(row[field].toString())
      })
    })
    setFilteredChallengeList(filteredRows)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const challengeStateListResponse =
          await ChallengeService.getChallengeStateList()
        if (challengeStateListResponse.data.challengeStates) {
          setStatuses(challengeStateListResponse.data.challengeStates)
          const periodListResponse = await PeriodService.getPeriodList()

          if (periodListResponse.data.periodList) {
            setPeriods(periodListResponse.data.periodList)

            const response = await ChallengeService.getChallengeList()
            const challengesFormatted = response.data.challenges.map((el) => {
              const statusName =
                challengeStateListResponse.data.challengeStates.find(
                  (ch) => ch.status === el.status,
                )?.name
              const periodName = periodListResponse.data.periodList.find(
                (p) => p.id === el.periodId,
              )?.name

              return {
                ...el,
                availableFrom: moment(el.availableFrom).format(
                  'YY-MM-DD HH:mm',
                ),
                availableTo: moment(el.availableTo).format('YY-MM-DD HH:mm'),
                validFrom: moment(el.validFrom).format('YY-MM-DD HH:mm'),
                validTo: moment(el.validTo).format('YY-MM-DD HH:mm'),
                statusName,
                periodName,
              }
            })

            setChallengeList(challengesFormatted)
            setFilteredChallengeList(challengesFormatted)
          }
        }
      } catch (error) {
        const _content =
          // (error.response && error.response.data) ||
          (error as Error).message || (error as Error).toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('pages.challenge.table.id'), width: 60 },
    {
      field: 'periodName',
      headerName: t('pages.challenge.table.period'),
      width: 140,
    },
    {
      field: 'name',
      headerName: t('pages.challenge.table.name'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'availableFrom',
      headerName: t('pages.challenge.table.availableFrom'),
      width: 120,
    },
    {
      field: 'availableTo',
      headerName: t('pages.challenge.table.availableTo'),
      width: 120,
    },
    {
      field: 'validFrom',
      headerName: t('pages.challenge.table.validFrom'),
      width: 120,
    },
    {
      field: 'validTo',
      headerName: t('pages.challenge.table.validTo'),
      width: 120,
    },
    { field: 'amateur', headerName: t('pages.challenge.table.amateur') },
    { field: 'expert', headerName: t('pages.challenge.table.expert') },
    { field: 'master', headerName: t('pages.challenge.table.master') },
    {
      field: 'overall',
      headerName: t('pages.challenge.table.overall'),
      width: 140,
    },
    {
      field: 'statusName',
      headerName: t('pages.challenge.table.status'),
    },
    {
      field: 'actions',
      headerName: t('pages.challenge.table.actions'),
      renderCell: (params) => (
        <>
          {params.row.isEditable && (
            <Tooltip title={`${t('pages.challenge.table.edit')}`}>
              <IconButton
                aria-label="edit"
                size="small"
                onClick={() =>
                  handleNavigationClick(`${path}/update/${params.row.id}`)
                }
              >
                <BorderColorIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
          {!params.row.isEditable && (
            <Tooltip title={`${t('pages.challenge.table.show')}`}>
              <IconButton
                aria-label="show"
                size="small"
                onClick={() =>
                  handleNavigationClick(`${path}/show/${params.row.id}`)
                }
              >
                <VisibilityOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
          {params.row.status !== 'completed' && params.row.isImportAllowed && (
            <Tooltip title={`${t('pages.challenge.table.importResults')}`}>
              <IconButton
                aria-label="show"
                size="small"
                onClick={() => {
                  handleChallengeResultsImportDialogClickOpen(params.row.id)
                }}
              >
                <ChallengeImportResultsIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
      sortable: false,
    },
  ]

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <PrimaryButton
            variant="contained"
            onClick={() => handleNavigationClick(`${path}/create`)}
          >
            {t('common.create')}
          </PrimaryButton>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGridTable
                components={{ Toolbar: ChallengesToolbar }}
                rows={filteredChallengeList}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                disableSelectionOnClick
                disableColumnMenu
                autoHeight
                componentsProps={{
                  toolbar: {
                    periods: periods,
                    statuses: statuses,
                    value: searchText,
                    onChange: (event: { target: { value: string } }) => {
                      setSearchText(event.target.value)
                      requestSearch(event.target.value, [
                        {
                          column: 'periodId',
                          value: periodValue,
                        },
                        {
                          column: 'status',
                          value: statusValue,
                        },
                      ])
                    },
                    periodValue: periodValue,
                    filterPeriod: (event: SelectChangeEvent) => {
                      setPeriodValue(event.target.value)
                      requestSearch(searchText, [
                        {
                          column: 'periodId',
                          value: event.target.value,
                        },
                        {
                          column: 'status',
                          value: statusValue,
                        },
                      ])
                    },
                    statusValue: statusValue,
                    filterStatus: (event: SelectChangeEvent) => {
                      setStatusValue(event.target.value)
                      requestSearch(searchText, [
                        {
                          column: 'periodId',
                          value: periodValue,
                        },
                        {
                          column: 'status',
                          value: event.target.value,
                        },
                      ])
                    },
                    clearSearch: () => {
                      setSearchText('')
                      requestSearch('', [
                        {
                          column: 'periodId',
                          value: periodValue,
                        },
                        {
                          column: 'status',
                          value: statusValue,
                        },
                      ])
                    },
                  },
                }}
              />
            </div>
          </div>
          {challengeId && (
            <ChallengeResultsImportDialog
              open={challengeResultsImportDialogOpen}
              handleClose={handleChallengeResultsImportDialogClose}
              challengeId={challengeId}
            />
          )}
        </>
      )}
    </>
  )
}

export default ChallengeList
