import axios from 'axios'
import { CompanyListReponse } from '../store/Company/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getCompanyList = (): Promise<CompanyListReponse> => {
  return axios.get(API_URL + '/api/admin/company', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getCompanyList,
}

export default exportedObject
