import axios from 'axios'
import { PeriodListReponse } from '../store/Period/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getPeriodList = (): Promise<PeriodListReponse> => {
  return axios.get(API_URL + '/api/admin/period', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getPeriodList,
}

export default exportedObject
