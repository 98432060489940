import React, { useEffect, useState } from 'react'
import { Alert, Stack, AlertColor } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import UserPlanService from '../../../../services/userPlan.service'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'

type UserPlanImportStep3Props = {
  plan: string
  acceptMessage: {
    type: AlertColor
    message: string
  } | null
  addedStores: { storeCentralId: string }[]
  skippedStores: { storeCentralId: string }[]
  handleSetAcceptMessage: (acceptMessage: {
    type: AlertColor
    message: string
  }) => void
  handleSetSkippedStores: (skippedStores: { storeCentralId: string }[]) => void
  handleSetAddedStores: (addedStores: { storeCentralId: string }[]) => void
  handleCancelImport: () => void
}

const UserPlanImportStep3: React.FunctionComponent<UserPlanImportStep3Props> =
  ({
    plan,
    acceptMessage,
    addedStores,
    skippedStores,
    handleSetAcceptMessage,
    handleSetSkippedStores,
    handleSetAddedStores,
    handleCancelImport,
  }) => {
    const { t } = useTranslation()
    const [acceptingImport, setAcceptingImport] = useState<boolean>(false)
    const [isStepVisible, setIsStepVisible] = useState<boolean>(false)

    const downloadXLSX = (data: any, name: string) => {
      const fileName = `${name}.xlsx`
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data)
      const wb: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, name)

      XLSX.writeFile(wb, fileName)
    }

    const acceptImport = async () => {
      if (plan) {
        try {
          setAcceptingImport(true)
          const importResponse = await UserPlanService.importAcceptResult(
            parseInt(plan),
          )

          if (importResponse.data.success) {
            handleSetAcceptMessage({
              type: 'success',
              message: t('pages.userPlanImport.messages.afterAcceptMessage'),
            })
          } else {
            toast.error(t('messages.error.generalError'))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setAcceptingImport(false)
          handleSetSkippedStores([])
          handleSetAddedStores([])
        }
      }
    }

    useEffect(() => {
      setIsStepVisible(true)
    }, [acceptMessage])

    useEffect(() => {
      setIsStepVisible(false)
    }, [plan])

    return (
      <>
        {isStepVisible && (
          <>
            {acceptMessage && (
              <Alert
                severity={acceptMessage.type}
                sx={{ my: 2 }}
                style={{ whiteSpace: 'pre-line' }}
              >
                {acceptMessage.message}
                {skippedStores.length > 0 && (
                  <SecondaryButton
                    onClick={() => downloadXLSX(skippedStores, 'pominiete')}
                    size="small"
                    style={{ display: 'block', marginTop: '10px' }}
                  >
                    {t('pages.userPlanImport.step3.downloadSkippedStores')}
                  </SecondaryButton>
                )}
              </Alert>
            )}

            <Stack
              className="buttons-container"
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              width="100%"
            >
              {acceptMessage && acceptMessage.type !== 'success' && (
                <SecondaryButton
                  onClick={handleCancelImport}
                  disabled={acceptingImport}
                >
                  {t('common.cancel')}
                </SecondaryButton>
              )}
              {acceptMessage && addedStores.length > 0 && (
                <PrimaryButton
                  onClick={acceptImport}
                  disabled={acceptingImport}
                >
                  {t('common.confirm')}
                </PrimaryButton>
              )}
            </Stack>
          </>
        )}
      </>
    )
  }

export default UserPlanImportStep3
