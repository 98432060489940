import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { CircularProgress, Stack } from '@mui/material'
import CompanyService from '../../../../services/company.service'
import StoreService from '../../../../services/store.service'
import { useTranslation } from 'react-i18next'
import StoresToolbar from '../partials/StoresToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Store } from '../../../../store/Store/types'
import { Option } from 'react-multi-select-component/dist/types/lib/interfaces'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
// import EditIcon from '@mui/icons-material/Edit'
import { User } from '../../../../store/Auth/types'
import { Column, ColumnInstance } from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import { pick } from 'lodash'

type StoresListProps = {
  user: User
}

const StoresList: FunctionComponent<StoresListProps> = ({ user }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredStoresList, setFilteredStoresList] = useState<Store[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])

  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const fetchIdRef = useRef(0)

  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])

  const generateTableColumns = useCallback(
    (stores: Store[]) => {
      const columns = []
      columns.push(
        // {
        //   accessor: 'edit',
        //   Header: '',
        //   width: 40,
        //   disableSortBy: true,
        //   Cell: (params: any) => (
        //     <IconButton
        //       onClick={() => console.log('edit')}
        //       size="small"
        //       style={{ padding: 0 }}
        //     >
        //       <EditIcon />
        //     </IconButton>
        //   ),
        // },
        {
          Header: t('pages.stores.table.userCompanyName').toString(),
          accessor: 'userCompanyName',
          width: 100,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'username',
          Header: t('pages.stores.table.username').toString(),
          width: 260,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userLastname',
          Header: t('pages.stores.table.userLastname').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeCentralId',
          Header: t('pages.stores.table.storeCentralId').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeInternalId',
          Header: t('pages.stores.table.storeInternalId').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeName',
          Header: t('pages.stores.table.storeName').toString(),
          width: 300,
          Cell: (params: any) => (
            <div style={{ whiteSpace: 'pre-wrap' }}>{params.value}</div>
          ),
        },
        {
          accessor: 'storeCity',
          Header: t('pages.stores.table.storeCity').toString(),
          width: 180,
          Cell: (params: any) => (
            <div style={{ whiteSpace: 'pre-wrap' }}>{params.value}</div>
          ),
        },
        {
          accessor: 'storeStreet',
          Header: t('pages.stores.table.storeStreet').toString(),
          width: 160,
          Cell: (params: any) => (
            <div style={{ whiteSpace: 'pre-wrap' }}>{params.value}</div>
          ),
        },
        {
          accessor: 'storeVoivodeshipName',
          Header: t('pages.stores.table.storeVoivodeshipName').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeBranchName',
          Header: t('pages.stores.table.storeBranchName').toString(),
          width: 120,
          Cell: (params: any) => (
            <div style={{ whiteSpace: 'pre-wrap' }}>{params.value}</div>
          ),
        },
        {
          accessor: 'storeSaleTypeName',
          Header: t('pages.stores.table.storeSaleTypeName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeTypeName',
          Header: t('pages.stores.table.storeTypeName').toString(),
          width: 200,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeActivityStateName',
          Header: t('pages.stores.table.storeActivityStateName').toString(),
          width: 110,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeParameterModified',
          Header: t('pages.stores.table.storeParameterModified').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
      )

      return columns
    },
    [t],
  )

  useEffect(() => {
    const fetchCompaniesData = async () => {
      try {
        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.id,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          setSelectedCompanies(multiSelectOptions)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchCompaniesData()
  }, [t])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        if (selectedCompanies.length) {
          setTableLoading(true)
          try {
            let sortColumn = ''
            let sortDirection = ''
            if (sortBy.length) {
              sortColumn = sortBy[0].id
              sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
            }

            setDownloadSortBy(sortColumn)
            setDownloadSortOrder(sortDirection)

            const page = ++pageIndex
            const storesResponse = await StoreService.getStores(
              selectedCompanies.map((company) => {
                return {
                  id: company.value,
                }
              }),
              searchValue,
              sortColumn,
              sortDirection,
              pageSize,
              page,
            )

            if (storesResponse.data.stores) {
              setTableColumns(generateTableColumns(storesResponse.data.stores))

              setFilteredStoresList(storesResponse.data.stores)

              setTotalCount(storesResponse.data.totalCount)
              setPageCount(Math.ceil(storesResponse.data.totalCount / pageSize))
            }
          } catch (error) {
            errorHandler(error, t)
          } finally {
            setSkipPageReset(true)
            setTableLoading(false)
          }
        } else {
          setTableColumns(generateTableColumns([]))

          setFilteredStoresList([])

          setTotalCount(0)
          setPageCount(0)
        }
      }
    },
    [t, selectedCompanies, searchValue, generateTableColumns],
  )

  const handleSelectedCompaniesChange = (companies: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setSelectedCompanies(companies)
  }

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const storesResponse = await StoreService.getStores(
        selectedCompanies.map((company) => {
          return {
            id: company.value,
          }
        }),
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
      )

      const dataStores = storesResponse.data.stores
      if (dataStores) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'userCompanyName',
            'username',
            'userLastname',
            'storeCentralId',
            'storeInternalId',
            'storeName',
            'storeCity',
            'storeStreet',
            'storeVoivodeshipName',
            'storeBranchName',
            'storeSaleTypeName',
            'storeTypeName',
            'storeActivityStateName',
            'storeParameterModified',
          ]
        }
        const filteredStoresData = dataStores.map((store) =>
          pick(store, visibleColumns),
        )

        const translatedHeaders = {
          userCompanyId: t('excel.stores.userCompanyId'),
          userCompanyName: t('excel.stores.userCompanyName'),
          userId: t('excel.stores.userId'),
          username: t('excel.stores.username'),
          userFirstname: t('excel.stores.userFirstname'),
          userLastname: t('excel.stores.userLastname'),
          storeId: t('excel.stores.storeId'),
          storeCentralId: t('excel.stores.storeCentralId'),
          storeInternalId: t('excel.stores.storeInternalId'),
          storeName: t('excel.stores.storeName'),
          storeCity: t('excel.stores.storeCity'),
          storeStreet: t('excel.stores.storeStreet'),
          storeHomeNumber: t('excel.stores.storeHomeNumber'),
          storePostalCode: t('excel.stores.storePostalCode'),
          storeVoivodeshipId: t('excel.stores.storeVoivodeshipId'),
          storeVoivodeshipName: t('excel.stores.storeVoivodeshipName'),
          storeBranchId: t('excel.stores.storeBranchId'),
          storeBranchName: t('excel.stores.storeBranchName'),
          storeSaleTypeId: t('excel.stores.storeSaleTypeId'),
          storeSaleTypeName: t('excel.stores.storeSaleTypeName'),
          storeActivityStateId: t('excel.stores.storeActivityStateId'),
          storeActivityStateName: t('excel.stores.storeActivityStateName'),
          storeTypeId: t('excel.stores.storeTypeId'),
          storeTypeName: t('excel.stores.storeTypeName'),
          storeParameterModified: t('excel.stores.storeParameterModified'),
        }

        const headers = [
          Object.keys(filteredStoresData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredStoresData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack display="flex" alignContent="end">
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('stores')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <StoresToolbar
            user={user}
            companies={companies}
            selectedCompanies={selectedCompanies}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            setSelectedCompanies={(companies) =>
              handleSelectedCompaniesChange(companies)
            }
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
            }}
          />
          <TableControlled
            columns={tableColumns}
            data={filteredStoresList}
            height="calc(100vh - 300px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'storeVoivodeshipName',
              'storeBranchName',
              'storeSaleTypeName',
              'storeTypeName',
              'storeActivityStateName',
              'storeParameterModified',
            ]}
            toggleVisibility={setColumnsVisibility}
          />
        </>
      )}
    </>
  )
}

export default StoresList
