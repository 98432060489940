import React, { FunctionComponent, useEffect, useState } from 'react'
import { Grid, List, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ChallengeService from '../../../../services/challenge.service'
import CompanyService from '../../../../services/company.service'
import ImageService from '../../../../services/image.service'
import {
  ChallengeDetails,
  ChallengeUnit,
} from '../../../../store/Challenge/types'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { handleNavigationClick } from '../../../../helpers/utils'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { isSuperAdmin, isCentralAdmin } from '../../../../helpers/checkRole'
import { useSelector, RootStateOrAny } from 'react-redux'
import { Image } from '../../../../store/Image/types'
import { Company } from '../../../../store/Company/types'

const ChallengeContainer = styled('div')(({ theme }) => ({
  'width-100': {
    width: '100%',
  },
  '.header': {
    fontSize: '1.25rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
    '&_number': {
      backgroundColor: theme.colorsPalette.gray.gray5,
      color: '#FFF',
      width: 46,
      height: 46,
      borderRadius: 23,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&_txt': {
      color: theme.colorsPalette.gray.gray5,
      fontWeight: 'bold',
      marginLeft: 10,
    },
  },
  '.challenges-main-grid_item': {
    paddingRight: 16,
    '&:not(:first-of-type)': {
      borderLeft: '1px solid',
      borderColor: theme.colorsPalette.gray.gray3,
    },
  },
}))

type ChallengeShowProps = {}
type ChallengeParams = {
  id: string
}

const ChallengeShow: FunctionComponent<ChallengeShowProps> = () => {
  let { id } = useParams<ChallengeParams>()
  const { t } = useTranslation()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const [challengeDetails, setChallengeDetails] =
    useState<ChallengeDetails | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [logo, setLogo] = useState<Image | null>(null)
  const [image, setImage] = useState<Image | null>(null)
  const [unit, setUnit] = useState<ChallengeUnit | null>(null)
  const [companies, setCompanies] = useState<Company[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const challengeDetailsResponse =
          await ChallengeService.getChallengeDetails(parseInt(id))
        if (challengeDetailsResponse.data) {
          setChallengeDetails(challengeDetailsResponse.data)

          if (isSuperAdmin(currentUser) || isCentralAdmin(currentUser)) {
            const companyListResponse = await CompanyService.getCompanyList()

            if (
              companyListResponse.data.companies &&
              challengeDetailsResponse.data.companies
            ) {
              const selectedCompanies: Company[] = []
              challengeDetailsResponse.data.companies.forEach((company) => {
                const foundCompany = companyListResponse.data.companies.find(
                  (c) => c.id === company.id,
                )
                if (foundCompany) {
                  selectedCompanies.push(foundCompany)
                }
              })
              setCompanies(selectedCompanies)
            }
          }

          if (challengeDetailsResponse.data.logoId) {
            const logoResponse = await ImageService.getImageContent(
              challengeDetailsResponse.data.logoId,
            )

            if (logoResponse) {
              setLogo(logoResponse.data)
            }
          }
          if (challengeDetailsResponse.data.imageId) {
            const imageResponse = await ImageService.getImageContent(
              challengeDetailsResponse.data.imageId,
            )

            if (imageResponse) {
              setImage(imageResponse.data)
            }
          }
          if (challengeDetailsResponse.data.unitId) {
            const unitListResponse =
              await ChallengeService.getChallengeUnitList()

            if (unitListResponse.data.challengeUnits) {
              setUnit(
                unitListResponse.data.challengeUnits.find(
                  (unit) => unit.id === challengeDetailsResponse.data.unitId,
                ) || null,
              )
            }
          }
        }
      } catch (error) {
        const _content =
          // (error.response && error.response.data) ||
          (error as Error).message || (error as Error).toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [id, currentUser])

  if (!challengeDetails) return null

  return (
    <ChallengeContainer>
      {loading && <LoadingSpinner />}
      {!loading && (
        <Grid className="challenges-main-grid" container spacing={2}>
          <Grid className="challenges-main-grid_item" item md={4} xs={12}>
            <div className="header">
              <div className="header_number">1</div>
              <div className="header_txt">
                {t('pages.challenge.step1.title')}
              </div>
            </div>
            <div>
              {t('pages.challenge.step1.id')}: {challengeDetails.challengeId}
              <br />
              {t('pages.challenge.step1.period')}: {challengeDetails.periodId}
              <br />
              {(isSuperAdmin(currentUser) || isCentralAdmin(currentUser)) &&
                companies && (
                  <>
                    {t('pages.challenge.step1.companies')}:
                    <List
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 200,
                        '& ul': { padding: 0 },
                      }}
                    >
                      <ul>
                        {companies.map((company) => (
                          <li>{company.name}</li>
                        ))}
                      </ul>
                    </List>
                  </>
                )}
              {t('pages.challenge.step1.name')}: {challengeDetails.name}
              <br />
              {t('pages.challenge.step1.logo')}:{' '}
              {logo && (
                <img
                  src={`data:${logo.imageMimeType};base64,${logo.imageContent}`}
                  alt={logo.name}
                  style={{
                    width: '50px',
                    height: '50px',
                    objectFit: 'contain',
                  }}
                />
              )}
              <br />
              {t('pages.challenge.step1.availableFrom')}:{' '}
              {challengeDetails.availableFrom}
              <br />
              {t('pages.challenge.step1.availableTo')}:{' '}
              {challengeDetails.availableTo}
              <br />
              {t('pages.challenge.step1.validFrom')}:{' '}
              {challengeDetails.validFrom}
              <br />
              {t('pages.challenge.step1.validTo')}: {challengeDetails.validTo}
              <br />
            </div>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-between"
              width="100%"
              className="buttons-container"
            >
              <SecondaryButton
                onClick={() => handleNavigationClick('challenges')}
              >
                {t('common.back')}
              </SecondaryButton>
            </Stack>
          </Grid>
          <Grid className="challenges-main-grid_item" item md={4} xs={12}>
            <div className="header">
              <div className="header_number">2</div>
              <div className="header_txt">
                {t('pages.challenge.step2.title')}
              </div>
            </div>
            <div>
              {challengeDetails.description && (
                <>
                  {t('pages.challenge.step2.description')}:{' '}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: challengeDetails.description,
                    }}
                  />
                </>
              )}
              {t('pages.challenge.step2.image')}:{' '}
              {image && (
                <img
                  src={`data:${image.imageMimeType};base64,${image.imageContent}`}
                  alt={image.name}
                  style={{
                    width: '150px',
                    height: '150px',
                    objectFit: 'contain',
                  }}
                />
              )}
              <br />
            </div>
          </Grid>
          <Grid className="challenges-main-grid_item" item md={4} xs={12}>
            <div className="header">
              <div className="header_number">3</div>
              <div className="header_txt">
                {t('pages.challenge.step3.title')}
              </div>
            </div>
            <div>
              {challengeDetails.amateur && (
                <>
                  {t(`pages.challenge.step3.amateur`)}:<br />+{' '}
                  {t(`pages.challenge.step3.xp`)}:{' '}
                  {challengeDetails.amateurXpPlus}
                  <br />+ {t(`pages.challenge.step3.vp`)}:{' '}
                  {challengeDetails.amateurVpPlus}
                  <br />- {t(`pages.challenge.step3.xp`)}:{' '}
                  {challengeDetails.amateurXpMinus}
                  <br />- {t(`pages.challenge.step3.vp`)}:{' '}
                  {challengeDetails.amateurVpMinus}
                  <br />
                </>
              )}
              {challengeDetails.expert && (
                <>
                  {t(`pages.challenge.step3.expert`)}:<br />+{' '}
                  {t(`pages.challenge.step3.xp`)}:{' '}
                  {challengeDetails.expertXpPlus}
                  <br />+ {t(`pages.challenge.step3.vp`)}:{' '}
                  {challengeDetails.expertVpPlus}
                  <br />- {t(`pages.challenge.step3.xp`)}:{' '}
                  {challengeDetails.expertXpMinus}
                  <br />- {t(`pages.challenge.step3.vp`)}:{' '}
                  {challengeDetails.expertVpMinus}
                  <br />
                </>
              )}
              {challengeDetails.master && (
                <>
                  {t(`pages.challenge.step3.master`)}:<br />+{' '}
                  {t(`pages.challenge.step3.xp`)}:{' '}
                  {challengeDetails.masterXpPlus}
                  <br />+ {t(`pages.challenge.step3.vp`)}:{' '}
                  {challengeDetails.masterVpPlus}
                  <br />- {t(`pages.challenge.step3.xp`)}:{' '}
                  {challengeDetails.masterXpMinus}
                  <br />- {t(`pages.challenge.step3.vp`)}:{' '}
                  {challengeDetails.masterVpMinus}
                  <br />
                </>
              )}
              {unit && (
                <>
                  {t('pages.challenge.step3.unit')}: {unit.name}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </ChallengeContainer>
  )
}

export default ChallengeShow
