import axios from 'axios'
import { UserImportListReponse } from '../store/User/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getUserImportList = (): Promise<UserImportListReponse> => {
  return axios.get(API_URL + '/api/admin/user/import', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getUserImportList,
}

export default exportedObject
