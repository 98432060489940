import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'
import { FormError } from '../../../../../store/types'
import {
  ChallengeDetails,
  ChallengeLevels,
  ChallengeStep3Errors,
  ChallengeStep3LevelPoints,
  ChallengeStep3RequestParams,
  ChallengeUnit,
} from '../../../../../store/Challenge/types'
import ChallengeService from '../../../../../services/challenge.service'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { styled } from '@mui/material/styles'
import ChallengeLevelForm from '../ChallengeLevelForm'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import ChallengeImportForm from './ChallengeImportForm'
import { toast } from 'react-toastify'
import LoadingSpinner from '../../../../shared/LoadingSpinner'

const ChallengeContainer = styled('div')(({ theme }) => ({
  '.form-style': {
    label: {
      color: theme.colorsPalette.gray.gray6,
      fontWeight: 'bold',
      fontSize: '.75rem',
    },
    '.MuiTextField-root': {
      marginTop: '0px !important',
      marginBottom: 0,
    },
  },
  '.margin-top-form': {
    marginTop: 20,
  },

  '.buttons-container': {
    marginTop: 20,
  },
}))

type ChallengeStep3FormProps = {
  challengeId: number | null
  challengeDetails?: ChallengeDetails
}

const ChallengeStep3Form: React.FunctionComponent<ChallengeStep3FormProps> = ({
  challengeId,
  challengeDetails,
}) => {
  const { t } = useTranslation()
  const [loadingStep3, setLoadingStep3] = useState<boolean>(true)
  const [savingStep3, setSavingStep3] = useState<boolean>(false)
  const [isStep3Saved, setIsStep3Saved] = useState<boolean>(false)
  const [isStep3Touched, setIsStep3Touched] = useState<boolean>(false)
  const [units, setUnits] = useState<ChallengeUnit[]>([])
  const [unitId, setUnitId] = useState<number | null>(null)
  const [levels, setLevels] = useState<ChallengeLevels>({
    amateur: false,
    expert: false,
    master: false,
  })

  const defaultPoints = {
    xpMinus: null,
    vpMinus: null,
    xpPlus: null,
    vpPlus: null,
  }
  const [points, setPoints] = useState<ChallengeStep3LevelPoints>({
    amateur: defaultPoints,
    expert: defaultPoints,
    master: defaultPoints,
  })

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultStep3FormErrors: ChallengeStep3Errors = {
    level: defaultError,
    unit: defaultError,
  }
  const [step3FormErrors, setStep3FormErrors] = useState<ChallengeStep3Errors>(
    defaultStep3FormErrors,
  )

  const handleLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsStep3Touched(true)
    setLevels({
      ...levels,
      [event.target.name]: event.target.checked,
    })
  }

  const resetStep3Form = () => {
    setIsStep3Touched(true)
    setStep3FormErrors(defaultStep3FormErrors)
    setPoints({
      amateur: defaultPoints,
      expert: defaultPoints,
      master: defaultPoints,
    })
    setLevels({
      amateur: false,
      expert: false,
      master: false,
    })
    setUnitId(null)
  }

  const saveStep3Form = async () => {
    setStep3FormErrors(defaultStep3FormErrors)
    const step3Errors = defaultStep3FormErrors

    if (!levels.amateur && !levels.expert && !levels.master) {
      setStep3FormErrors({
        ...step3Errors,
        level: {
          error: true,
          message: t('pages.challenge.step3.errors.levelRequired'),
        },
      })
      return
    } else if (
      levels.amateur &&
      (points.amateur.vpMinus === null ||
        points.amateur.xpMinus === null ||
        points.amateur.vpPlus === null ||
        points.amateur.xpPlus === null)
    ) {
      setStep3FormErrors({
        ...step3Errors,
        level: {
          error: true,
          message: t('pages.challenge.step3.errors.amateurPointsRequired'),
        },
      })
      return
    } else if (
      levels.expert &&
      (points.expert.vpMinus === null ||
        points.expert.xpMinus === null ||
        points.expert.vpPlus === null ||
        points.expert.xpPlus === null)
    ) {
      setStep3FormErrors({
        ...step3Errors,
        level: {
          error: true,
          message: t('pages.challenge.step3.errors.expertPointsRequired'),
        },
      })
      return
    } else if (
      levels.master &&
      (points.master.vpMinus === null ||
        points.master.xpMinus === null ||
        points.master.vpPlus === null ||
        points.master.xpPlus === null)
    ) {
      setStep3FormErrors({
        ...step3Errors,
        level: {
          error: true,
          message: t('pages.challenge.step3.errors.masterPointsRequired'),
        },
      })
      return
    } else if (!unitId) {
      setStep3FormErrors({
        ...step3Errors,
        unit: {
          error: true,
          message: t('pages.challenge.step3.errors.unitRequired'),
        },
      })
      return
    }

    if (challengeId) {
      const formParams: ChallengeStep3RequestParams = {
        challengeId,
        amateur: levels.amateur,
        amateurXpPlus: levels.amateur ? points.amateur.xpPlus : null,
        amateurVpPlus: levels.amateur ? points.amateur.vpPlus : null,
        amateurXpMinus: levels.amateur ? points.amateur.xpMinus : null,
        amateurVpMinus: levels.amateur ? points.amateur.vpMinus : null,
        expert: levels.expert,
        expertXpPlus: levels.expert ? points.expert.xpPlus : null,
        expertVpPlus: levels.expert ? points.expert.vpPlus : null,
        expertXpMinus: levels.expert ? points.expert.xpMinus : null,
        expertVpMinus: levels.expert ? points.expert.vpMinus : null,
        master: levels.master,
        masterXpPlus: levels.master ? points.master.xpPlus : null,
        masterVpPlus: levels.master ? points.master.vpPlus : null,
        masterXpMinus: levels.master ? points.master.xpMinus : null,
        masterVpMinus: levels.master ? points.master.vpMinus : null,
        unitId,
      }

      try {
        setSavingStep3(true)
        const createOrUpdateChallengeStep3Response =
          await ChallengeService.updateChallengeStep3(formParams)

        if (createOrUpdateChallengeStep3Response.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
          setIsStep3Saved(true)
          setIsStep3Touched(false)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setSavingStep3(false)
      }
    }
  }

  const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsStep3Touched(true)
    setUnitId(parseInt((event.target as HTMLInputElement).value))
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const unitListResponse = await ChallengeService.getChallengeUnitList()

        if (unitListResponse.data.challengeUnits) {
          setUnits(unitListResponse.data.challengeUnits)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoadingStep3(false)
      }
    }
    fetchData()
  }, [t])

  useEffect(() => {
    // load data if update
    if (challengeDetails) {
      setPoints({
        amateur: {
          xpMinus: challengeDetails.amateurXpMinus,
          vpMinus: challengeDetails.amateurVpMinus,
          xpPlus: challengeDetails.amateurXpPlus,
          vpPlus: challengeDetails.amateurVpPlus,
        },
        expert: {
          xpMinus: challengeDetails.expertXpMinus,
          vpMinus: challengeDetails.expertVpMinus,
          xpPlus: challengeDetails.expertXpPlus,
          vpPlus: challengeDetails.expertVpPlus,
        },
        master: {
          xpMinus: challengeDetails.masterXpMinus,
          vpMinus: challengeDetails.masterVpMinus,
          xpPlus: challengeDetails.masterXpPlus,
          vpPlus: challengeDetails.masterVpPlus,
        },
      })
      setLevels({
        amateur: challengeDetails.amateur,
        expert: challengeDetails.expert,
        master: challengeDetails.master,
      })
      setUnitId(challengeDetails.unitId)
    }
  }, [challengeDetails])

  const isImportPossible = (): boolean => {
    if (isStep3Saved) {
      return (
        (levels.amateur || levels.expert || levels.master) && unitId !== null
      )
    } else if (challengeDetails) {
      return (
        challengeDetails.isEditable &&
        (challengeDetails.amateur ||
          challengeDetails.expert ||
          challengeDetails.master) &&
        challengeDetails.unitId !== null
      )
    }
    return false
  }

  return (
    <ChallengeContainer>
      {loadingStep3 && <LoadingSpinner />}
      {!loadingStep3 && (
        <Box
          className="form-style"
          component="form"
          sx={{
            '& .MuiTextField-root': { mb: 1, mt: 1 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
          noValidate
          autoComplete="off"
        >
          <FormControl error={step3FormErrors.level.error}>
            {Object.entries(levels).map((level) => (
              <ChallengeLevelForm
                key={level[0]}
                level={level}
                points={(points as any)[level[0]]}
                handleLevelChange={handleLevelChange}
                handleLevelPointsChange={(levelName, levelPoints) => {
                  setIsStep3Touched(true)
                  setPoints({
                    ...points,
                    [levelName]: levelPoints,
                  })
                }}
              />
            ))}
            {step3FormErrors.level.error && (
              <FormHelperText>{step3FormErrors.level.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl component="fieldset" error={step3FormErrors.unit.error}>
            <RadioGroup
              row
              name="challenge-units-group"
              onChange={handleUnitChange}
              value={unitId}
            >
              {units.map((unit) => (
                <FormControlLabel
                  key={unit.id}
                  value={unit.id}
                  control={<Radio />}
                  label={unit.name}
                />
              ))}
            </RadioGroup>
            {step3FormErrors.unit.error && (
              <FormHelperText>{step3FormErrors.unit.message}</FormHelperText>
            )}
          </FormControl>
          <Stack
            className="buttons-container"
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            width="100%"
          >
            <SecondaryButton onClick={resetStep3Form} disabled={savingStep3}>
              {t('common.reset')}
            </SecondaryButton>
            <PrimaryButton
              onClick={saveStep3Form}
              disabled={
                savingStep3 ||
                !challengeId ||
                (challengeDetails && !challengeDetails?.isEditable)
              }
            >
              {t('common.save')}
            </PrimaryButton>
          </Stack>
        </Box>
      )}
      <ChallengeImportForm
        challengeId={challengeId}
        challengeLevels={levels}
        isImportPossible={isImportPossible()}
        isStep3Touched={isStep3Touched}
      />
    </ChallengeContainer>
  )
}

export default ChallengeStep3Form
