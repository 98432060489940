import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { FormControl, Select, MenuItem } from '@mui/material'
import { Period } from '../../../../store/Period/types'
import { ChallengeState } from '../../../../store/Challenge/types'

interface ChallengesToolbarProps {
  clearSearch: () => void
  onChange: () => void
  filterPeriod: () => void
  filterStatus: () => void
  value: string
  periodValue: string
  statusValue: string
  periods: Period[]
  statuses: ChallengeState[]
}

export default function ChallengesToolbar(props: ChallengesToolbarProps) {
  const { t } = useTranslation()

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 10,
        alignItems: 'flex-end',
      }}
    >
      <div>
        <FormControl size="small" sx={{ marginRight: 1 }}>
          <label>{t('pages.challenge.toolbar.filters.status')}</label>
          <Select
            id="status-select"
            onChange={props.filterStatus}
            value={props.statusValue}
            defaultValue={props.statusValue}
          >
            <MenuItem value="all">
              {t('pages.challenge.toolbar.filters.all')}
            </MenuItem>
            {props.statuses.map((status) => (
              <MenuItem value={status.status} key={status.status}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small">
          <label>{t('pages.challenge.toolbar.filters.period')}</label>
          <Select
            id="period-select"
            onChange={props.filterPeriod}
            value={props.periodValue}
            defaultValue={props.periodValue}
          >
            <MenuItem value="all">
              {t('pages.challenge.toolbar.filters.all')}
            </MenuItem>
            {props.periods.map((period) => (
              <MenuItem value={period.id} key={period.id}>
                {period.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TextField
        variant="outlined"
        size="small"
        value={props.value}
        onChange={props.onChange}
        placeholder={t('pages.challenge.table.search')}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  )
}
