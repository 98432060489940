import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import ImageService from '../../../../services/image.service'
import { UserImage } from '../../../../store/Image/types'
import { Avatar, CircularProgress } from '@mui/material'

type PlanImageProps = {
  imageId: number
}

const PlanImage: React.FunctionComponent<PlanImageProps> = ({
  imageId,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [image, setImage] = useState<UserImage | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const planImageResponse = await ImageService.getImageContent(
          imageId,
        )
        if (planImageResponse.data.imageContent) {
          setImage(planImageResponse.data)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, imageId])

  return (
    <>
      {loading && (
        <Avatar
          variant="square"
          sx={{
            width: 70,
            height: 70,
            marginLeft: 'auto',
            marginRight: 'auto',
            background: 'transparent',
          }}
        >
          <CircularProgress />
        </Avatar>
      )}
      {!loading && image && (
        <Avatar
          variant="square"
          src={`data:${image.imageMimeType};base64,${image.imageContent}`}
          sx={{
            width: 70,
            height: 70,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          imgProps={{
            style: {
              objectFit: 'contain',
            }
          }}
        />
      )}
    </>
  )
}

export default PlanImage
