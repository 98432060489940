import React, { useEffect, useState } from 'react'
import {
  Stack,
  Typography,
  FormControl,
  Grid,
  Alert,
  List,
  ListItem,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  styled,
} from '@mui/material'
import { MultiSelect } from 'react-multi-select-component'
import { Option } from 'react-multi-select-component/dist/types/lib/interfaces'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import HomeService from '../../../../services/home.service'
import RegionService from '../../../../services/region.service'
import { PeriodResult } from '../../../../store/Home/types'
import LoadingSpinner from '../../../shared/LoadingSpinner'

type BorderLinearProgressProps = LinearProgressProps & { barColor?: string }

const BorderLinearProgress = styled(LinearProgress)(({ barColor }) => ({
  marginTop: 3,
  height: 14,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: barColor,
  },
})) as React.ComponentType<BorderLinearProgressProps>

const PercentageBadge = styled('div')({
  backgroundColor: '#f1f1f1',
  border: '1px solid #dcdcdc',
  borderRadius: '5px',
  fontSize: '75%',
  padding: '0px 4px',
  display: 'inline-block',
})

type PeriodPlanResultProps = {
  colors: string[]
}

const PeriodPlanResult: React.FunctionComponent<PeriodPlanResultProps> = ({
  colors,
}) => {
  const { t } = useTranslation()
  const [regions, setRegions] = useState<Option[]>([])
  const [selectedRegions, setSelectedRegions] = useState<Option[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [periodResults, setPeriodResults] = useState<PeriodResult[]>([])

  const customValueRenderer = (selected: { label: any }[], _options: any) => {
    return selected.length
      ? t('multiSelect.selectedItems', { length: selected.length })
      : t('multiSelect.select')
  }

  const filterOptions = (options: any[], filter: string | RegExp) => {
    if (!filter) {
      return options
    }
    const re = new RegExp(filter, 'i')
    return options.filter(({ label }) => label && label.match(re))
  }

  const handleRegionsChange = async (regions: Option[]) => {
    setSelectedRegions(regions)

    if (regions.length > 0) {
      const homePlanResultListResponse = await HomeService.getHomePlanResult(
        regions.map((region) => region.value),
      )
      if (homePlanResultListResponse.data.planResults) {
        const periodResultsWithVisibility =
          homePlanResultListResponse.data.planResults.map((plan) => {
            return {
              ...plan,
              visible: true,
            }
          })
        setPeriodResults(periodResultsWithVisibility)
      }
    } else {
      setPeriodResults([])
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const regionListResponse = await RegionService.getRegionList()

        if (regionListResponse.data.regions) {
          const multiSelectOptions: Option[] = []
          regionListResponse.data.regions.forEach((region) =>
            multiSelectOptions.push({
              value: region.id,
              label: region.name,
            }),
          )
          setRegions(multiSelectOptions)
          setSelectedRegions(multiSelectOptions)
        }

        if (regionListResponse.data.regions.length > 0) {
          const homePlanResultListResponse =
            await HomeService.getHomePlanResult(
              regionListResponse.data.regions.map((region) => region.id),
            )
          if (homePlanResultListResponse.data.planResults) {
            const periodResultsWithVisibility =
              homePlanResultListResponse.data.planResults.map((plan) => {
                return {
                  ...plan,
                  visible: true,
                }
              })
            setPeriodResults(periodResultsWithVisibility)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div
          style={{
            background: '#f1f1f1',
            borderRadius: '3px',
            padding: '15px 20px',
            height: '100%',
            margin: '0 20px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div"
              fontWeight="bold"
            >
              {t('pages.home.periodPlanResult.title')}
            </Typography>
            <FormControl size="small" style={{ width: 300 }}>
              <label>{t('pages.home.periodPlanResult.selectRegions')}</label>
              <MultiSelect
                options={regions}
                value={selectedRegions}
                onChange={handleRegionsChange}
                labelledBy={t('multiSelect.labelledBy')}
                ClearSelectedIcon={<></>}
                filterOptions={filterOptions}
                overrideStrings={{
                  allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
                  clearSearch: t('multiSelect.clearSearch'),
                  noOptions: t('multiSelect.noOptions'),
                  search: t('multiSelect.search'),
                  selectAll: t('multiSelect.selectAll'),
                  selectAllFiltered: t('multiSelect.selectAllFiltered'),
                  selectSomeItems: t('multiSelect.selectSomeItems'),
                }}
                valueRenderer={customValueRenderer}
              />
            </FormControl>
          </Stack>
          <Grid container>
            {periodResults.length === 0 && (
              <Grid item xs={12} mt={1}>
                <Alert severity="info" variant="filled">
                  {t('pages.home.periodPlanResult.noDataToDisplay')}
                </Alert>
              </Grid>
            )}
            {periodResults.map((plan) => (
              <Grid item xs={3} key={plan.periodId}>
                <div style={{ background: '#f1f1f1', padding: '10px' }}>
                  {plan.periodName}
                  <List
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      padding: 0,
                      marginTop: '8px',
                    }}
                  >
                    {plan.results.map((p, i) => (
                      <ListItem
                        key={p.planId}
                        alignItems="flex-start"
                        style={{
                          padding: '6px 6px 2px 6px',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography
                                variant="caption"
                                gutterBottom
                                component="div"
                                letterSpacing={0}
                              >
                                {p.planName}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={6}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={p.value >= 100 ? 100 : p.value}
                              barColor={colors[i]}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            textAlign="right"
                            style={{ paddingTop: '12px' }}
                          >
                            <PercentageBadge>
                              {Math.round((p.value + Number.EPSILON) * 100) /
                                100}
                              %
                            </PercentageBadge>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export default PeriodPlanResult
