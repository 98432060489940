import React, { useEffect, useState } from 'react'
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Plan } from '../../../../store/Plan/types'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import PlanService from '../../../../services/plan.service'

type UserPlanImportStep1Props = {
  plan: string
  handlePlanChange: (event: SelectChangeEvent) => void
}

const UserPlanImportStep1: React.FunctionComponent<UserPlanImportStep1Props> =
  ({ plan, handlePlanChange }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(true)
    const [plans, setPlans] = useState<Plan[]>([])

    useEffect(() => {
      const fetchData = async () => {
        try {
          const planListResponse = await PlanService.getPlanList()
          if (planListResponse.data.plans) {
            setPlans(planListResponse.data.plans)
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setLoading(false)
        }
      }
      fetchData()
    }, [t])

    return (
      <>
        {loading && <LoadingSpinner />}
        {!loading && (
          <FormControl size="small" fullWidth>
            <label>{t('pages.userPlanImport.step1.plan')}</label>
            <Select id="plan-select" value={plan} onChange={handlePlanChange}>
              {plans.map((plan) => (
                <MenuItem value={plan.id} key={plan.id}>
                  {plan.name} ({plan.periodName})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </>
    )
  }

export default UserPlanImportStep1
