import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Box, FormControl, FormHelperText, Stack } from '@mui/material'
import { Image } from '../../../../../store/Image/types'
import { FormError } from '../../../../../store/types'
import {
  ChallengeDetails,
  ChallengeStep2Errors,
  ChallengeStep2RequestParams,
} from '../../../../../store/Challenge/types'
import ImageService from '../../../../../services/image.service'
import ChallengeService from '../../../../../services/challenge.service'
import ChallengeImagePicker from '../ChallengeImagePicker'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { toast } from 'react-toastify'

const ChallengeContainer = styled('div')(({ theme }) => ({
  '.form-style': {
    label: {
      color: theme.colorsPalette.gray.gray6,
      fontWeight: 'bold',
      fontSize: '.75rem',
    },
    '.MuiTextField-root': {
      marginTop: '0px !important',
      marginBottom: 0,
    },
  },
  '.margin-top-form': {
    marginTop: 20,
  },

  '.buttons-container': {
    marginTop: 20,
  },
}))

const EDITOR_MAX_LENGTH = 2000
type ChallengeStep2FormProps = {
  challengeId: number | null
  challengeDetails?: ChallengeDetails
}

const ChallengeStep2Form: React.FunctionComponent<ChallengeStep2FormProps> = ({
  challengeId,
  challengeDetails,
}) => {
  const { t } = useTranslation()
  const hasFetchedData = useRef(false)
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  )
  const [description, setDescription] = useState<string | null>(null)
  const [descriptionLength, setDescriptionLength] = useState<number>(0)
  const [image, setImage] = useState<Image | null>(null)
  const [openImageDialog, setOpenImageDialog] = useState(false)
  const [savingStep2, setSavingStep2] = useState<boolean>(false)

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultStep2FormErrors: ChallengeStep2Errors = {
    image: defaultError,
  }
  const [step2FormErrors, setStep2FormErrors] = useState<ChallengeStep2Errors>(
    defaultStep2FormErrors,
  )

  useEffect(() => {
    setDescriptionLength(
      editorState.getCurrentContent().getPlainText('').length,
    )
    setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState])

  const onEditorStateChange = (currentEditorState: EditorState) => {
    const contentState = currentEditorState.getCurrentContent()
    const oldContent = editorState.getCurrentContent()
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= EDITOR_MAX_LENGTH
    ) {
      setEditorState(currentEditorState)
    } else {
      const newEditorState = EditorState.moveFocusToEnd(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character',
        ),
      )
      setEditorState(newEditorState)
    }
  }

  const handleOpenImageDialog = () => {
    setOpenImageDialog(true)
  }

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false)
  }

  const handleImageSelect = useCallback(async (imageId: number) => {
    try {
      const imageResponse = await ImageService.getImageContent(imageId)

      if (imageResponse) {
        setImage(imageResponse.data)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      handleCloseImageDialog()
    }
  }, [t])

  const resetStep2Form = () => {
    setStep2FormErrors(defaultStep2FormErrors)
    setEditorState(EditorState.createEmpty())
    setDescription(null)
    setImage(null)
  }

  const saveStep2Form = async () => {
    setStep2FormErrors(defaultStep2FormErrors)
    const step2Errors = defaultStep2FormErrors

    if (!image) {
      setStep2FormErrors({
        ...step2Errors,
        image: {
          error: true,
          message: t('pages.challenge.step2.errors.imageRequired'),
        },
      })
      return
    }

    if (challengeId) {
      const formParams: ChallengeStep2RequestParams = {
        challengeId,
        description,
        imageId: image.id,
      }

      try {
        setSavingStep2(true)
        const createOrUpdateChallengeStep2Response =
          await ChallengeService.updateChallengeStep2(formParams)

        if (createOrUpdateChallengeStep2Response.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setSavingStep2(false)
      }
    }
  }

  useEffect(() => {
    // load data if update
    if (challengeDetails && !hasFetchedData.current) {
      if (challengeDetails.description) {
        const blocksFromHTML = convertFromHTML(challengeDetails.description)
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        )

        setEditorState(EditorState.createWithContent(content))
        setDescription(challengeDetails.description)
      }
      if (challengeDetails.imageId) {
        handleImageSelect(challengeDetails.imageId)
      }
      hasFetchedData.current = true
    }
  }, [challengeDetails, handleImageSelect])

  return (
    <ChallengeContainer>
      <Box
        className="form-style"
        component="form"
        sx={{
          '& .MuiTextField-root': { mb: 1, mt: 1 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl fullWidth>
          <label>{t('pages.challenge.step2.description')}</label>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              options: ['inline', 'list', 'colorPicker', 'emoji'],
              inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough'],
              },
            }}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {descriptionLength} / {EDITOR_MAX_LENGTH}
          </Stack>
        </FormControl>
        <FormControl error={step2FormErrors.image.error}>
          <label>{t('pages.challenge.step2.image')}</label>
          {image && (
            <img
              src={`data:${image.imageMimeType};base64,${image.imageContent}`}
              alt={image.name}
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain',
              }}
            />
          )}
          <SecondaryButton onClick={handleOpenImageDialog}>
            {t('pages.challenge.step2.selectFile')}
          </SecondaryButton>
          {step2FormErrors.image.error && (
            <FormHelperText>{step2FormErrors.image.message}</FormHelperText>
          )}
        </FormControl>
        <ChallengeImagePicker
          open={openImageDialog}
          handleClose={handleCloseImageDialog}
          handleImageSelect={handleImageSelect}
        />
        <Stack
          className="buttons-container"
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          width="100%"
        >
          <SecondaryButton onClick={resetStep2Form} disabled={savingStep2}>
            {t('common.reset')}
          </SecondaryButton>
          <PrimaryButton
            onClick={saveStep2Form}
            disabled={
              savingStep2 ||
              !challengeId ||
              (challengeDetails && !challengeDetails?.isEditable)
            }
          >
            {t('common.save')}
          </PrimaryButton>
        </Stack>
      </Box>
    </ChallengeContainer>
  )
}

export default ChallengeStep2Form
