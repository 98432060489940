import React, { FunctionComponent, useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Tabs,
  Tab,
  DialogActions,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Pagination,
  Stack,
} from '@mui/material'
import ImageService from '../../../../services/image.service'
import { Image, ImageType } from '../../../../store/Image/types'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../shared/LoadingSpinner'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `logo-tab-${index}`,
    'aria-controls': `logo-tabpanel-${index}`,
  }
}

type ChallengeLogoPickerProps = {
  open: boolean
  handleClose: () => void
  handleImageSelect: (id: number) => void
}

const ChallengeLogoPicker: FunctionComponent<ChallengeLogoPickerProps> = ({
  open,
  handleClose,
  handleImageSelect,
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingImages, setLoadingImages] = useState(true)
  const [imageTypes, setImageTypes] = useState<ImageType[]>([])
  const [images, setImages] = useState<Image[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 12

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPage(page)
    fetchImages(imageTypes[value].code, limit, limit * (page - 1))
  }

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setPage(1)
    setValue(newValue)
    fetchImages(imageTypes[newValue].code, limit, 0)
  }

  const fetchImages = async (code: string, limit: number, offset: number) => {
    setLoadingImages(true)

    try {
      const imageListResponse = await ImageService.getImageList(
        code,
        limit,
        offset,
      )

      if (imageListResponse.data.images) {
        setImages(imageListResponse.data.images)
        setTotalCount(imageListResponse.data.totalCount)
      }
    } catch (error) {
      const _content =
        // (error.response && error.response.data) ||
        (error as Error).message || (error as Error).toString()

      console.warn(_content)
    } finally {
      setLoadingImages(false)
    }
  }

  useEffect(() => {
    const fetchLogoDialogData = async () => {
      setPage(1)
      setValue(0)
      setLoading(true)
      try {
        const imageTypesResponse = await ImageService.getImageTypeList()

        if (imageTypesResponse.data.imageTypes) {
          const filteredImageTypes = imageTypesResponse.data.imageTypes.filter(
            (type) => ['ML', 'CL'].includes(type.code),
          )
          setImageTypes(filteredImageTypes)

          fetchImages(filteredImageTypes[0].code, limit, 0)
        }
      } catch (error) {
        const _content =
          // (error.response && error.response.data) ||
          (error as Error).message || (error as Error).toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchLogoDialogData()
    }
  }, [open])

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{t('pages.challenge.step1.selectLogo')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {loading && <LoadingSpinner />}
          {!loading && (
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: '100%',
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="logo tabs"
                >
                  {imageTypes.map((imageType, i) => (
                    <Tab
                      key={imageType.id}
                      label={imageType.name}
                      {...a11yProps(i)}
                    />
                  ))}
                </Tabs>
              </Box>
              {imageTypes.map((imageType, i) => (
                <TabPanel value={value} index={i} key={imageType.id}>
                  {loadingImages && <LoadingSpinner />}
                  {!loadingImages && images.length === 0 && (
                    <div>{t('pages.challenge.step1.noLogosToDisplay')}</div>
                  )}
                  {!loadingImages && images.length > 0 && (
                    <>
                      <ImageList
                        sx={{ width: '100%', height: 310 }}
                        cols={6}
                        rowHeight={150}
                      >
                        {images.map((image) => (
                          <ImageListItem
                            key={image.id}
                            onClick={() => handleImageSelect(image.id)}
                          >
                            <img
                              src={`data:${image.imageMimeType};base64,${image.imageContent}`}
                              alt={image.name}
                              loading="lazy"
                              style={{ objectFit: 'contain' }}
                            />
                            <ImageListItemBar
                              title={image.name}
                              subtitle={<span>{image.description}</span>}
                              position="below"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                      <Stack spacing={2}>
                        <Pagination
                          count={Math.ceil(totalCount / limit)}
                          page={page}
                          onChange={handlePageChange}
                        />
                      </Stack>
                    </>
                  )}
                </TabPanel>
              ))}
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChallengeLogoPicker
