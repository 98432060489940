import React, { FunctionComponent } from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  List,
  Toolbar,
  Collapse,
  ListItemButton,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import DashboardIcon from '@mui/icons-material/Dashboard'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { styled } from '@mui/material/styles'
import { handleNavigationClick } from '../../../helpers/utils'
import TcreateLogo from '../../../../src/assets/images/logos/tcreate-logo.png'
import ActionLogo from '../../../../src/assets/images/logos/action-logo.png'
import { isSuperAdmin } from '../../../helpers/checkRole'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const drawerWidth = 240

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#373739',
    color: '#DCDCDC',
    fill: '#DCDCDC',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      '.nav-footer-container': {
        display: 'none',
      },
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
  '.tcreate-logo': {
    position: 'absolute',
    right: '93px',
  },
  '.hide-icon': {
    fill: theme.colorsPalette.yellow.main,
  },
  '.side-navbar-container': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '20px',
    overflowX: 'hidden',
  },
  '.nav-footer-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.colorsPalette.gray.gray4,
    fontSize: '.875rem',
    textAlign: 'center',
    '.action-logo': {
      width: '100%',
      maxWidth: '100px',
    },
    '.nav-footer-txt': {
      '&_version': {
        margin: '10px 0px',
      },
      a: {
        color: theme.colorsPalette.gray.gray4,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
}))

type NavigationProps = {
  open: boolean
  handleDrawerClose: () => void
  currentUser: any
}

const Navigation: FunctionComponent<NavigationProps> = ({
  open,
  handleDrawerClose,
  currentUser,
}) => {
  const appVersion = process.env.REACT_APP_VERSION

  const [openHelp, setOpenHelp] = React.useState(false)

  const handleHelpClick = () => {
    setOpenHelp(!openHelp)
  }

  const renderListItems = () => {
    return (
      <div>
        <ListItem button onClick={() => handleNavigationClick('home')}>
          <ListItemIcon>
            <DashboardIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Pulpit" />
        </ListItem>
        <ListItem button onClick={() => handleNavigationClick('challenges')}>
          <ListItemIcon>
            <NoteAltIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Wyzwania" />
        </ListItem>
        {isSuperAdmin(currentUser) && (
          <ListItem
            button
            onClick={() => handleNavigationClick('user-plan-import')}
          >
            <ListItemIcon>
              <UploadFileIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="User Plan Import" />
          </ListItem>
        )}
        <ListItem button onClick={() => handleNavigationClick('user-plans')}>
          <ListItemIcon>
            <InsertChartOutlinedTwoToneIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Plany" />
        </ListItem>
        <ListItem button onClick={() => handleNavigationClick('stores')}>
          <ListItemIcon>
            <ApartmentIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Sklepy" />
        </ListItem>
        <ListItemButton onClick={handleHelpClick}>
          <ListItemIcon>
            <ContactSupportIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Pomoc" />
          {openHelp ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openHelp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component="a"
              sx={{ pl: 4 }}
              href="https://panel-mpt.salesgame.pl/docs/Panel administracyjny instrukcja v.17.02.pdf"
              target="_blank"
            >
              <ListItemIcon>
                <PictureAsPdfIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Instrukcja (.pdf)" />
            </ListItem>
          </List>
        </Collapse>
      </div>
    )
  }

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <img className="tcreate-logo" src={TcreateLogo} alt="" />
          <ChevronLeftIcon className="hide-icon" />
        </IconButton>
      </Toolbar>
      <Divider />
      <div className="side-navbar-container">
        <List>{renderListItems()}</List>
        <div className="nav-footer-container">
          <img className="action-logo" src={ActionLogo} alt="" />
          <div className="nav-footer-txt">
            <div className="nav-footer-txt_version">ver. {appVersion}</div>
            <div className="nav-footer-txt_www">
              <a
                href="https://tcreate.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.tcreate.pl
              </a>
            </div>
            <div className="nav-footer-txt_phone">512 802 802</div>
            <div className="nav-footer-txt_mail">
              <a
                href="mailto:info@tcreate.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@tcreate.pl
              </a>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default Navigation
